import React, { useEffect } from "react";
import styled, { withTheme } from "styled-components/macro";

import {
  getClienteFromUserGroup,
  getGlosaRol,
} from "../globalfunctions/GlobalFunctions";

import { useReactiveVar } from "@apollo/client";
import {
  Grid,
  Hidden,
  InputBase,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  Typography,
  Tooltip,
  Button,
} from "@material-ui/core";

import { Menu as MenuIcon } from "@material-ui/icons";

import UserDropdown from "./UserDropdown";

import {
  current_user,
  current_selection,
  usuario_config,
  selectedLanguage,
  selectedMetric,
} from "../graphql/localstate/localState";
import { translate } from "../globalfunctions/translation";
import SelectLanguage from "./SelectLanguage";
import SelectMetric from "./SelectMetric";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const AppBarComponent = ({ onDrawerToggle }) => {
  const curr_selection = useReactiveVar(current_selection);
  const usr_config = useReactiveVar(usuario_config);
  const language = useReactiveVar(selectedLanguage);
  const selectedMetricVar = useReactiveVar(selectedMetric);

  //    const [qgetUsuarioConfig, {
  //     loading: loading_usuario,
  //     error: error_usuario,
  //     data: data_usuario,
  //     networkStatus,
  //     refetch
  // }
  // ] = useLazyQuery(getUsuarioConfig, {
  //     fetchPolicy: 'cache-and-network',
  //     variables: {},
  //     notifyOnNetworkStatusChange: true
  // }
  // );

  // React.useEffect(() => {

  // Auth.currentAuthenticatedUser().then((user) =>
  //     {

  //       let cl_id = 999999
  //       const groups = user.signInUserSession.accessToken.payload["cognito:groups"]
  //       if (groups.length >0) {
  //         cl_id = getClienteFromUserGroup(groups[0])
  //       }
  //       current_user (
  //         {
  //           id:user.attributes.sub,
  //           name:user.username,
  //           email:user.attributes.email,
  //           groups:user.signInUserSession.accessToken.payload["cognito:groups"],
  //           cliente_id: cl_id,
  //         }
  //       )

  //       return user;
  //     })
  //       .catch((e) =>
  //       {
  //         return e;
  //       });

  // }, [])

  // React.useEffect(() =>
  //   {

  //       qgetUsuarioConfig({ variables: {username: Auth?.user?.username} });

  //   }, [Auth?.user?.username]);

  // React.useEffect(()=> {

  //   if (data_usuario) {
  //     let aux = Object.assign({}, data_usuario.getUsuarioConfig);
  //     usuario_config(aux)
  //     current_user(
  //      { username: data_usuario.getUsuarioConfig.username}
  //     )
  //   }

  // // },[loading_usuario])

  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Hidden mdUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>

            <Grid item>
              <Typography variant="h4" gutterBottom>
                {curr_selection?.cliente_nombre !== undefined
                  ? `${translate(43, language)} ${
                      curr_selection?.cliente_nombre
                    }`
                  : ""}
              </Typography>
            </Grid>
            {/* <Grid item>
          <NotificationsDropdown />
          </Grid>
          */}

            <Grid item xs />

            <Grid item>
              {/* <MessagesDropdown />*/}
              {/*<NotificationsDropdown />*/}
              <UserDropdown />
            </Grid>

            <Grid item>
              <Typography>
                {" "}
                {(usr_config === undefined
                  ? ""
                  : usr_config?.username?.toUpperCase()) +
                  (usr_config === undefined
                    ? ""
                    : " (" +
                      getGlosaRol(usr_config?.usuario?.rol_id) +
                      ")")}{" "}
              </Typography>

              {/* <MessagesDropdown />*/}
              {/*<NotificationsDropdown />*/}
              {/* <AmplifySignOut buttonText="Cerrar Sesión" /> */}
            </Grid>
            <Grid item>
              <SelectLanguage />
            </Grid>
            <Grid item>
              <SelectMetric />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(AppBarComponent);

import React from "react";
import styled from "styled-components/macro";
import { Link, NavLink } from "react-router-dom";

import Helmet from "react-helmet";

import { Button as MuiButton, Typography } from "@material-ui/core";
import { spacing } from "@material-ui/system";

import { ReactComponent as Logo } from "../../vendor/logo1.svg";

const Button = styled(MuiButton)(spacing);

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)}px;
  text-align: center;
  background: transparent;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

const BrandIcon = styled(Logo)`
width: 350px;
height: 150px;
margin-left: -60px;
`;


function Page404() {
  return (
    <Wrapper>
      <Helmet title="404 Error" />
 
        <BrandIcon />{" "}

      <Typography component="h2" variant="h5" align="center" gutterBottom>
        Página no encontrada
      </Typography>
      <Typography component="h2" variant="body1" align="center" gutterBottom>
        La página que estás buscando no existe.
      </Typography>

      <Button
        component={Link}
        to="/"
        variant="contained"
        color="secondary"
        mt={2}
      >
        Volver a EP Gestión
      </Button>
    </Wrapper>
  );
}

export default Page404;

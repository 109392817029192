import React from "react";
import styled from "styled-components/macro";
import { Power, User } from "react-feather";
import { useHistory, NavLink } from "react-router-dom";
import {
  current_user,
  current_selection,
  login_state,
  selectedLanguage,
} from "../graphql/localstate/localState";
import { Auth } from "aws-amplify";
import { UpdateCurrentSelection } from "../globalfunctions/LocalStorageFunctions";
import { useReactiveVar } from "@apollo/client";

import { translate } from "../globalfunctions/translation";

import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
  Link,
} from "@material-ui/core";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function UserDropdown() {
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const history = useHistory();
  const selectedLanguageVar = useReactiveVar(selectedLanguage);

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const goToMyAccount = () => {
    history.push("/mi_cuenta");
  };

  const handleSignOut = async () => {
    try {
      await Auth.signOut();
      history.push("/");
      current_user(undefined);
      current_selection(undefined);
      login_state(undefined);
      UpdateCurrentSelection(undefined);
      localStorage.clear();
    } catch (error) {
      //console.log('error signing out: ', error);
    }
    return localStorage.clear();
  };

  return (
    <React.Fragment>
      <IconButton
        aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
        aria-haspopup="true"
        onClick={toggleMenu}
        color="inherit"
      >
        <User />
      </IconButton>

      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={goToMyAccount}>
          {translate(64, selectedLanguageVar)}
        </MenuItem>

        <MenuItem onClick={handleSignOut}>
          {translate(345, selectedLanguageVar)}
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default UserDropdown;

import React from "react";
import { useSelector } from "react-redux";

import { Helmet } from "react-helmet";

import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { StylesProvider } from "@material-ui/styles";
import { ThemeProvider } from "styled-components/macro";

import createTheme from "./theme";
import Routes2 from "./routes2/Routes2";
import "./amplifyStyles.css";

/* IMPORT  AUTH */
import Amplify, { Auth, I18n } from "aws-amplify";
import { AmplifyAuthenticator, AmplifySignIn } from "@aws-amplify/ui-react";
import { onAuthUIStateChange } from "@aws-amplify/ui-components";

/* IMPORT APOLLO */
import { createAuthLink } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import {
  ApolloProvider,
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  useReactiveVar,
} from "@apollo/client";

import { login_state, selectedLanguage } from "./graphql/localstate/localState";

import AppSyncConfig from "./aws-exports";

I18n.setLanguage("es");
const dict = {
  es: {
    "Forgot your password?": "¿Olvidó contraseña?",
    "Reset password": "Restablecer contraseña",
    "No account?": "¿No tiene cuenta?",
    "Create account": "Crear cuenta",
    email: "Usuario",
    "Username cannot be empty": "Completa los datos requeridos",
    "Have an account?": "¿Ya tienes cuenta?",
    "Sign in": "Inicia sesión",
    "Confirm Sign up": "Confirmar registro",
    "Confirmation Code": "Código de confirmación",
    "Lost your code?": "¿No llegó el código?",
    "Enter your code": "Ingresa tu código",
    "Resend Code": "Reenviar",
    "Back to Sign In": "Volver a Inicio de sesión",
    Confirm: "CONFIRMAR",
  },
};
I18n.putVocabularies(dict);

/* AMPLIFY */
Amplify.configure({
  Auth: {
    region: "us-east-1",
    userPoolId: "us-east-1_bMTlDZTAN",
    userPoolWebClientId: "1jbos3fjeli4qq9u6oa1fciv6b",
    mandatorySignIn: true,
  },
});

/* BETA */

/* APOLLO */
/*https://medium.com/@guillac124/create-your-custom-apollo-client-for-aws-appsync-to-use-hooks-2d5cbce29db5 */
/*https://dev.to/danielbayerlein/migrate-apollo-from-v2-to-v3-in-conjunction-with-aws-appsync-16c0 */

const config = {
  url: AppSyncConfig.graphqlEndpoint,
  region: AppSyncConfig.region,
  auth: {
    type: AppSyncConfig.authenticationType,
    jwtToken: async () =>
      (await Auth.currentSession()).getIdToken().getJwtToken(),
  },
};

const client = new ApolloClient({
  link: ApolloLink.from([
    createAuthLink(config),
    createSubscriptionHandshakeLink(config),
  ]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
  },
});

function App() {
  const theme = useSelector((state) => state.themeReducer);
  React.useEffect(() => {
    return onAuthUIStateChange((newAuthState, authData) => {
      login_state({ state: newAuthState, auth_data: authData });
    });
  }, []);

  React.useEffect(() => {
    return selectedLanguage();
  }, [selectedLanguage()]);

  return (
    <React.Fragment>
      <AmplifyAuthenticator>
        <AmplifySignIn
          //handleAuthStateChange={handleAuthStateChange}
          hideSignUp
          id="custom-sign-in"
          slot="sign-in"
          submitButtonText="INICIAR SESIÓN"
          headerText="EP GESTIÓN"
          usernameAlias="email"
          formFields={[
            {
              type: "email",
              label: "email",
              placeholder: "email",
              required: true,
            },
            {
              type: "password",
              label: "Contraseña",
              placeholder: "contraseña",
              required: true,
            },
          ]}
        />

        <Helmet titleTemplate="%s | EP Gestión" defaultTitle="EP Gestión" />

        <StylesProvider injectFirst>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <MuiThemeProvider theme={createTheme(theme.currentTheme)}>
              <ThemeProvider theme={createTheme(theme.currentTheme)}>
                {/* APOLLO */}
                <ApolloProvider client={client}>
                  <Routes2 />
                </ApolloProvider>
              </ThemeProvider>
            </MuiThemeProvider>
          </MuiPickersUtilsProvider>
        </StylesProvider>
      </AmplifyAuthenticator>
    </React.Fragment>
  );
}

export default App;
// export default withAuthenticator(App);

/*export default withAuthenticator(App, {
   theme: createTheme,
   usernameAttributes: 'email'
});
*/

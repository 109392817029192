import gql from 'graphql-tag'

export default gql`
 query getUsuarioConfig($username: String) {
 getUsuarioConfig(username: $username) {
	cliente_config {
        cliente_id
        tipo_cliente
        temporada_inicio
        select_temporada_id
        select_cliente_id
        select_cliente_nombre
        select_productor_id
        select_productor_nombre
        select_predio_id
        select_predio_nombre
        select_cuartel_id
        select_cuartel_nombre
        lenguaje
        sistema_metricas
    }
	permisos_predio {
        id
        predio_id
        r_cliente_id
        r_predio_nombre
        rol_predio
        subrol_predio
        username
    }
    username
    usuario {
      username
      rut
      nombre
      email
      telefono
      cliente_id
      rol_id
    }
    superadmin
  }
 }
`;
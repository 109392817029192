import React, { useState } from "react";
import {
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@material-ui/core";
import {
  selectedMetric,
  selectedLanguage,
} from "../graphql/localstate/localState";
import { useReactiveVar } from "@apollo/client";
// import { translate } from "../globalfunctions/translation";
import Flags from "country-flag-icons/react/3x2";

const SelectMetric = () => {
  const selectedMetricVar = useReactiveVar(selectedMetric);
  const selectedLanguageVar = useReactiveVar(selectedLanguage);

  const handleChange = (event) => {
    selectedMetric(event.target.value);
    localStorage.setItem("localStorageMetric", event.target.value);
  };

  const localStorageMetric = localStorage.getItem("localStorageMetric");
  // console.log(`localStorageMetric: ${localStorageMetric}`);
  // console.log(`selectedMetricVar: ${selectedMetricVar}`);

  return (
    <FormControl
      fullWidth
      style={{
        width: 50,
        marginLeft: 10,
      }}
    >
      <InputLabel id="selectMetric">
        {selectedLanguageVar === "english" ? "Metric" : "Métricas"}
      </InputLabel>
      <Select
        style={{
          marginTop: 20,
        }}
        labelId="selectMetric"
        id="select"
        value={selectedMetricVar}
        onChange={handleChange}
      >
        <MenuItem value={"cl"}>
          <Flags.CL title="Chile" style={{ maxWidth: 50 }} />
        </MenuItem>
        <MenuItem value={"us"}>
          <Flags.US title="United States" style={{ maxWidth: 50 }} />
        </MenuItem>
        <MenuItem value={"pe"}>
          <Flags.PE title="Perú" style={{ maxWidth: 50 }} />
        </MenuItem>
        <MenuItem value={"ze"}>
          <Flags.ZA title="South Africa" style={{ maxWidth: 50 }} />
        </MenuItem>
        <MenuItem value={"es"}>
          <Flags.ES title="España" style={{ maxWidth: 50 }} />
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default SelectMetric;

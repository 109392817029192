import { current_selection } from "../graphql/localstate/localState";

export function UpdateCurrentSelection(new_object) {
  current_selection(new_object);
  window.localStorage.setItem(
    "currentStorageSelection",
    JSON.stringify(new_object)
  );
}

export function existLocalStorage(local_storage) {
  if (local_storage !== null) {
    return true;
  } else {
    return false;
  }
}

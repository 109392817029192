import { makeVar } from "@apollo/client";

const localStorageLanguage = localStorage.getItem("localStorageLanguage");
// console.log(localStorageLanguage);
const localStorageMetric = localStorage.getItem("localStorageMetric");
// console.log(localStorageMetric);

// SELECTED PREDIO / CUARTEL
export const currentSelectedBlock = makeVar();

// MAPBOX DATASET FOR PREDIO GEOREF
export const currentMapBoxDataSet = makeVar();

// MAPBOX DATASET FOR CUARTEL GEOREF
export const cuartelMapBoxDataSet = makeVar(null);

// HILERAS GEOREF DATA
export const currentHileras = makeVar(null);

// PLANTAS GEOREF DATA
export const currentPlantas = makeVar(null);

// STORE ORIGINAL HILERAS DIRECTION
export const originalHilerasDirection = makeVar(null);

// SINGLE HILERA TO MODIFY
export const hileraBeingEditted = makeVar(null);

// PLANTAS FROM THE SELECTED HILERA
export const plantasFromSelectedHilera = makeVar([]);

// Create Reactive variable
export const current_user = makeVar();

// Rentabilidad reactive variable:
export const globalRentabilidad = makeVar(null);

export const rentabilidadPredio = makeVar(null);

export const globalCostoIngreso = makeVar(null);

export const current_selection = makeVar();

export const usuario_config = makeVar();

export const login_state = makeVar();

export const dataPlantas = makeVar(null);

export const selectedLanguage = makeVar(
  localStorageLanguage !== null || undefined ? localStorageLanguage : "spanish"
);

export const selectedMetric = makeVar(
  localStorageMetric !== null || undefined ? localStorageMetric : "cl"
);

// export const selectedLanguage = () => {
//   if (localStorageLanguage !== null || undefined) {
//     return makeVar(localStorageLanguage);
//   } else {
//     return makeVar("spanish");
//   }
// };

// export const selectedMetric = () => {
//   if (localStorageMetric !== null || localStorageMetric !== undefined) {
//     return makeVar(localStorageMetric);
//   } else {
//     return makeVar("spanish");
//   }
// };

// object with react Vars as keys

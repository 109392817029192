import React from "react";
import {
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@material-ui/core";
import { selectedLanguage } from "../graphql/localstate/localState";
import { useReactiveVar } from "@apollo/client";

const SelectLanguage = () => {
  const selectedLanguageVar = useReactiveVar(selectedLanguage);

  // default value

  const handleChange = (event) => {
    selectedLanguage(event.target.value);
    localStorage.setItem("localStorageLanguage", event.target.value);
  };

  return (
    <FormControl
      fullWidth
      style={{ width: 75, marginLeft: 20, borderRadius: 25 }}
    >
      <InputLabel id="selectLanguage">
        {selectedLanguageVar === "english" ? "Language" : "Idioma"}
      </InputLabel>
      <Select
        style={{
          marginTop: 25,
        }}
        labelId="selectLanguage"
        id="select"
        value={selectedLanguageVar}
        label={selectedLanguageVar === "english" ? "Language" : "Idioma"}
        onChange={handleChange}
      >
        <MenuItem value={"english"}>English</MenuItem>
        <MenuItem value={"spanish"}>Español</MenuItem>
      </Select>
    </FormControl>
  );
};

export default SelectLanguage;

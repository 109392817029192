import { selectedLanguage } from "../graphql/localstate/localState";

export const spanish = {
  0: "Cargadores Planta",
  1: "Yemas Cargador",
  2: "Pitones Planta",
  3: "Yemas Piton",
  4: "Yemas Planta",
  5: "Yemas",
  6: "Fertilidad",
  7: "Fertilidad Yemas (%)",
  8: "Racimos Planta",
  9: "Racimos",
  10: "Desbrota",
  11: "Racimos Cargador",
  12: "Racimos Pitón",
  13: "Racimos Planta",
  14: "Racimos Ha",
  15: "Bayas Racimo",
  16: "Bayas",
  17: "Calibre",
  18: "Calibre Envero",
  19: "Porc. Crecimiento (%)",
  20: "Calibre Promedio",
  21: "Peso Baya Promedio",
  22: "Kilos",
  23: "Exportación",
  24: "Poda",
  25: "Selección Temporada",
  26: "Productores",
  27: "Predios",
  28: "Cuarteles",
  29: "Superficie Cuarteles",
  30: "Cajas Export. tot",
  31: "Variedades",
  32: "Producción",
  33: "EP Actual",
  34: "Evolución EP",
  35: "Variedad",
  36: "Productor",
  37: "Predio",
  38: "Cuartel",
  39: "Producción Estimada",
  40: "Ha",
  41: "Cajas",
  42: "Cajas Export. Totales",
  43: "Empresa",
  44: "Inicio",
  45: "Rangos Métricas EP",
  46: "Mantenedor BD",
  47: "Clientes",
  48: "Patrones",
  49: "Sistemas de Conducción",
  50: "Usuarios Empresas",
  51: "Detalle Usuario",
  52: "Documentación",
  53: "Bienvenido",
  54: "Sobre EP Gestión",
  55: "Empezando",
  56: "Variables de entorno",
  57: "Despliegue",
  58: "Temas",
  59: "Manejo de estado",
  60: "Llamadas API",
  61: "ESLint & Prettier",
  62: "Apoyo",
  63: "Versión Actual",
  64: "Mi Cuenta",
  65: "Ecuación Productiva",
  66: "Creación y actualización de la Ecuación Productiva en cada etapa de la gestión agrícola.",
  67: "Pronóstico Actualizado de la Producción",
  68: "Pronóstico actualizado de la producción en todas las etapas de la gestión agrícola.",
  69: "Programación de Labores",
  70: "Generación de programas de trabajo alineados con la Ecuación Productiva actualizada.",
  71: "Control de Labores",
  72: "Monitoreo y control en línea de la ejecución delabores mediante la recolección de mediciones en terreno.",
  73: "Control de Rangos de Métricas EP",
  74: "Generación de feedback para mostrar rangos bajos, normales y altos de las métricas más importantes de la Ecuación Productiva.",
  75: "Visualización en Mapas",
  76: "Selección de capas de información a mostrar indicadores de la EP, control y avances de labores a nivel de planta, cuartel y predio.",
  77: "Soporte",
  78: "Preguntas Frecuentes",
  79: "Términos del Servicio",
  80: "EP - Gestión",
  81: "Selección Empresa",
  82: "Selección Temporada",
  83: "Superficie",
  84: "Cajas Export. Tot.",
  85: "La Empresa no tiene Ecuaciones Productivas en la temporada",
  86: "Sin información",
  87: "Modo",
  88: "Resumido",
  89: "Completo",
  90: "Ordenar por:",
  91: "Retroceder/Avanzar",
  92: "Plantas Teo. Cuartel",
  93: "Plantas Teo. Total",
  94: "Etapa Actual",
  95: "Fecha EP",
  96: "Calidad Plantas",
  97: "Inicio",
  98: "Arreglo",
  99: "Porc. Crecimiento (%)",
  100: "Num. Cuarteles",
  101: "Final",
  102: "Selección Productor",
  103: "El productor no tiene Ecuaciones Productivas en la temporada",
  104: "Selección Predio",
  105: "Cajas Export. Estimadas",
  106: "El predio no tiene Ecuaciones Productivas en la temporada ",
  107: "Estado Cuarteles",
  108: "Labores",
  109: "Imprimir",
  110: "Mapa Predio",
  111: "Avance Cuarteles",
  112: "Debes crear un Dataset en MapBox para visualizar capas en el Predio ",
  113: "Fondo",
  114: "Sin Fondo",
  115: "Satélite",
  116: "Capa",
  117: "Cajas Exp. Totales",
  118: "Plantas",
  119: "% Avance",
  120: "Avance Detallado",
  121: "Cargando Capas",
  122: "Mapa Ampliado",
  123: "No hay mapas asociados a este predio",
  124: "Labores En Curso",
  125: "Etapa",
  126: "Fecha Prog.",
  127: "Dias Prog.",
  128: "Inicio Labor",
  129: "Mapa no encontrado",
  130: "Ir a Cuartel",
  131: "Ver cuartel",
  132: "No existen labores asociadas a este Predio ",
  133: "Cuartel/EP Etapa",
  134: "Selección Cuartel ",
  135: "¿Está seguro que desea inicializar cuartel?",
  136: "Esta acción no se puede deshacer",
  137: "No, Volver",
  138: "Sí, Inicializar",
  139: "Variedad - Patrón",
  140: "Año",
  141: "Plantas Reales Cuartel",
  142: "El Cuartel no ha sido inicializado para ingresar Ecuaciones Productivas en la temporada ",
  143: "Editar",
  144: "Inicializar Cuartel",
  145: "para la temporada",
  146: "Mapa Cuartel",
  147: "Para cerrar la etapa, debes crear EP de la etapa.",
  148: "La etapa fue cerrada exitosamente.",
  149: "Ocurrió un error al cerrar la etapa.",
  150: "Se volvió a la etapa anterior",
  151: "Ocurrió un error volviendo a la etapa anterior.",
  152: "La EP ha sido creada exitosamente.",
  153: `Ocurrió un error al crear la EP.`,
  154: "La EP ha sido actualizada exitosamente.",
  155: "Ocurrió un error al actualizar la EP.",
  156: "Etapa: ",
  157: "Volver a la Etapa Anterior ",
  158: "Cerrar Etapa",
  159: "Editar EP Etapa",
  160: "Crear EP Etapa",
  161: "Cancelar",
  162: "Guardar",
  163: "Guardar EP",
  164: "¿Está seguro de cerrar la Etapa ",
  165: "¿Está seguro de volver a Etapa ",
  166: "Esta acción no se puede deshacer y borrará la EP de la Etapa actual",
  167: "Si, cerrar la etapa",
  168: "Si, ir a la etapa anterior",
  169: "Actualizando la EP.",
  170: "Crear desde EP anterior",
  171: "Crear desde EP Real",
  172: "Refrescar Control",
  173: "Desvío",
  174: "Exportación (%)",
  175: "Final (Cerrada)",
  176: "No existen mediciones para esta etapa.",
  177: "Última",
  178: "Plantas Medidas",
  179: "Plantas Totales",
  180: "Plantas Quemadas",
  181: "Bayas Planta",
  182: "Bayas Medidas Por Planta",
  183: "Mapa Cuartel ",
  184: "Seleccionar Etapa",
  185: "Capa de visualización",
  186: "Yemas Planta",
  187: "Calibre Proyectado",
  188: "Cajas Ha",
  189: "Calibre Final",
  190: "% Crecimiento Calibre",
  191: "Kilos",
  192: "Todos",
  193: "Avance Labores",
  194: "Angulo Elevación",
  195: "Dimensiones",
  196: "Antecedentes Cuartel",
  197: "Este cuartel no tiene mapa asociado.",
  198: "La labor fue eliminada exitosamente",
  199: "Ocurrió un error al eliminar la Labor",
  200: "Fecha Programación",
  201: "Días estimados",
  202: "Fecha Final",
  203: "Métricas desde EP Etapa",
  204: "Detalle de Labor",
  205: "Detalle de Ajuste",
  206: "Describe las labores",
  207: "Describe los ajustes",
  208: "Detalle de control",
  209: "Describe los controles",
  210: "Crear Labor",
  211: "Crear Ajuste",
  212: "Volver",
  213: "Resumen Labor",
  214: "Labor",
  215: "Avance/Medido",
  216: "¿Estás seguro que deseas eliminar ",
  217: "Si, eliminar",
  218: "Labor y Ajustes",
  219: "Crear Labor",
  220: "La labor para la etapa ",
  221: " se actualizó exitosamente",
  222: "Ocurrió un error al crear la labor para la etapa ",
  223: "Detalle de Labor ",
  224: "Tipo de Labor",
  225: "Principal",
  226: "Ajuste",
  227: "Detalle de Labor Principal",
  228: "Detalle de Ajuste",
  229: "Descripción",
  230: "Detalle de Control Principal",
  231: "Detalle de Control de Ajuste",
  232: "Mapa Avance Labor",
  233: "Esta etapa no tiene labores.",
  234: "EPs Etapas",
  235: "Por el momento, no existen EPs en la temporada.",
  236: "Fecha Inicio",
  237: "Labor (EP Anterior y Ajustes)",
  238: "Avance",
  239: "Control (EP Real)",
  240: "Medido",
  241: "Fecha Prog. Inicio",
  242: "creada exitosamente",
  243: "Ocurrió un error al crear la variedad ",
  244: "Agregar Variedad",
  245: "Nombre de la variedad",
  246: "Exponente",
  247: "Multiplicador X",
  248: "Rango Inferor",
  249: "Rango Superior",
  250: "Crear Variedad",
  251: "Campo Obligatorio",
  252: "Datos editados exitosamente",
  253: "Creación",
  254: "Sin registro",
  255: "Detalle",
  256: "DETALLE DE VARIEDAD",
  257: "Mantenedor DB",
  258: "Error en la carga de datos...",
  259: "Cliente",
  260: "creado exitosamente",
  261: "Ocurrió un error al crear el cliente ",
  262: "Agregar Cliente",
  263: "Tipo de Cliente",
  264: "1. MultiProductor (Empresa)",
  265: "2. MonoProductor-MultiPredio (Productor)",
  266: "3. MonoProductor-MonoPredio (Predio)",
  267: "Temporada Inicio",
  268: "Cliente eliminado exitosamente",
  269: "Ocurrió un error al eliminar al cliente",
  270: "Fecha inválida",
  271: "No encontrada",
  272: "Última modificación",
  273: "DETALLE DE CLIENTE",
  274: "El máximo de carácteres del nombre no debe superar 10 carácteres",
  275: "Ocurrió un error al ",
  276: "crear al",
  277: "Agregar",
  278: "Patrón",
  279: "Distancia entre",
  280: "Distancia sobre",
  281: "Riego",
  282: "Año de Plantación",
  283: "Latitud Centro",
  284: "Longitud Centro",
  285: "Crear",
  286: "eliminado exitosamente",
  287: " eliminar el",
  288: "SIN REGISTRO",
  289: " sin Latitud/Longitud",
  290: "DETALLE DE CUARTEL: ",
  291: "DETALLE DE PATRÓN: ",
  292: "al ",
  293: "Dirección",
  294: "Información no proporcionada",
  295: "DETALLE DE PREDIO",
  296: "DETALLE DE PRODUCTORES",
  297: "DETALLE DE SISTEMA DE CONDUCCIÓN",
  298: "Sistema",
  299: "Modificación",
  300: "Nombre",
  301: "Exponente",
  302: "Métrica",
  303: "Bajo",
  304: "Normal",
  305: "Alto",
  306: "Usuario",
  307: "Rut",
  308: "Teléfono",
  309: "Role",
  310: "Sistema Métrico",
  311: "Sistema Imperial",
  312: "Anterior",
  313: "Editar y Cerrar Etapas",
  314: "Sólo Editar",
  315: "Sólo Ver",
  316: "Sin Acceso",
  317: "Selección Cliente",
  318: "No existen usuarios asociados a este Cliente",
  319: "Peso Racimo Promedio",
  320: "Mercado Interno",
  321: "Plantas Teo.",
  322: "Programación",
  323: "Ejecución",
  324: "Generación EP Etapa",
  325: "El cuartel fue inicializado exitosamente.",
  326: "Ocurrió un error al inicializar al Cuartel.",
  327: "Superficie",
  328: "sobre teórico.",
  329: "Plantas Reales",
  330: "se creó exitosamente",
  331: "la labor",
  332: " sobre lo normal",
  333: " normal",
  334: " bajo lo normal",
  335: "Rango",
  336: "La etapa fue cerrada exitosamente",
  337: "EP",
  338: "Guía de Métricas",
  339: "País",
  340: "Peso",
  341: "Caja",
  342: "Distancia",
  343: "Pulgadas",
  344: "Milimetros",
  345: "Cerrar Sesión",
  346: "Formato de Rut debe contener guión",
  347: "Campo Obligatorio",
  348: "Nombre de Usuario",
  349: "Autorización",
  350: " actualizado exitosamente",
  351: " actualizar el ",
  352: "Permisos actualizados",
  353: "asignando permisos al ",
  354: "Invitación reenviada correctamente a ",
  355: " al reenviar invitación al",
  356: "Confirmado",
  357: "En espera de cambio de contraseña",
  358: "No confirmado",
  359: "Reenviar ",
  360: "invitación",
  361: "Habilitado",
  362: "Sí",
  363: "Estado",
  364: "Permisos",
  365: "No existen permisos asociados al Usuario ",
  366: "Usuarios",
  367: "Alarmas",
  368: "Selección Métrica",
  369: "Comentario",
  370: "Alarma",
  371: "Peso Baya",
  372: "DETALLE DE ALARMA",
  373: "Todas",
  374: "Ayuda",
  375: "Desbrota",
  376: "Parrón",
  377: "Hectárea (Há)",
  378: "Rentabilidad Estimada (US$)",
  379: "Precio Escenario Conservador",
  380: "Precio Escenario Esperado",
  381: "Precio Escenario Optimista",
  382: "Precio Referencia por Caja",
  383: "Precio Mercado Interno por Kilo",
  384: "Otros Ingresos por Caja",
  385: "Otros Ingresos por ",
  386: "Costo Hasta Cosecha Há",
  387: "Fue/Será Cosechado?",
  388: "Costo Cosecha y Limpiar Caja",
  389: "Fue/Será Embalado?",
  390: "Costo Embalaje y Puesto en Frio por Caja",
  391: "Otros Costos por Há",
  392: "Costos",
  393: "Escenarios",
  394: "Precios",
  395: "Ingreso",
  396: "Rentabilidad",
  397: "Parámetros Rentabilidad",
  398: "Georeferenciación",
  399: "Editar Hileras",
  400: "Editar Plantas",
  401: "Cajas Totales",
  402: "Hubo un error creando el usuario",
  403: "usuario",
  500: "Mis Datos",
  501: "Predios Asociados",
  502: "Antecedentes",
  503: "Hilera",
  504: "Fila",
  505: "Calidad",
  506: "Borde Cuartel",
  507: "Otras Etapas",
  508: "Avance Nº Plantas",
  509: "Nº Plantas Hilera",
  510: "Avance Hilera",
  511: "Kilos Totales Mercado Interno",
};

export const english = {
  0: "Canes / Vine",
  1: "Buds / Cane",
  2: "Spurs / Vine",
  3: "Buds / Spur",
  4: "Buds / Vine",
  5: "Buds",
  6: "Fertility",
  7: "Bud Fertility (%)",
  8: "Bunches / Vine",
  9: "Bunches",
  10: "Suckering",
  11: "Bunches / Cane",
  12: "Bunches / Spur",
  13: "Bunches / Vine",
  14: "Bunches / Acre",
  15: "Berries / Bunch",
  16: "Berries",
  17: "Berry Diameter",
  18: "Berry Diameter At Veraison",
  19: "Growing Increase %",
  20: "Average Berry Diameter ",
  21: "Average Berry Weight ",
  22: "Kilos",
  23: "Export",
  24: "Prunning",
  25: "Season",
  26: "Growers",
  27: "Farms",
  28: "Blocks",
  29: "Block Surface",
  30: "Total Export Boxes",
  31: "Varieties",
  32: "Crop",
  33: "Current PE",
  34: "PE Evolution",
  35: "Variety",
  36: "Grower",
  37: "Farm",
  38: "Block ",
  39: "CROP (ESTIMATED)",
  40: "Acre",
  41: "Boxes",
  42: "Total Crop (Boxes)",
  43: "Company",
  44: "Home",
  45: "Metric Ranges",
  46: "DB Maintainer",
  47: "Customers",
  48: "Rootstock",
  49: "Trellis System",
  50: "Company Users",
  51: "User Details",
  52: "Documentation",
  53: "Welcome",
  54: "About EP Gestion",
  55: "Getting Started",
  56: "Environment Variables",
  57: "DPEloyment",
  58: "Theming",
  59: "State Management",
  60: "API Calls",
  61: "ESLint & Prettier",
  62: "Support",
  63: "Version",
  64: "My Account",
  65: "Production Ecuation",
  66: "Creation and update of the productive ecuation in each stage of the agricultural management.",
  67: "Updated Production Forecast",
  68: "Updated Production Forecast in every stage of the agricultural management.",
  69: "Labor Scheduling",
  70: "Generation Of Work Programs Aligned With The Updated Productive Ecuation.",
  71: "Work Management",
  72: "Online control and monitoring of work execution through the collection of metrics on site.",
  73: "PE Metric Range Management",
  74: "Feedback generation to show low, normal and high of the most important metrics of the Production Ecuation.",
  75: "Map Visualization",
  76: "Data Layer Selection to show PE KPI's, control and progress of work at vine level, block and Farm.",
  77: "Support",
  78: "Frequently Asked Questions",
  79: "Terms of Service",
  80: "PE Management",
  81: "Select Company",
  82: "Season",
  83: "Surface",
  84: "Total Export. Boxes",
  85: "This company does not have any Productive Ecuations in this season",
  86: "Without Info",
  87: "Mode",
  88: "Summarized",
  89: "Complete",
  90: "Sort by:",
  91: "Go Back / Forward",
  92: "Theoretical Vines / Block",
  93: "Theoretical Total Vines",
  94: "Current Stage",
  95: "PE Date",
  96: "Vine Quality",
  97: "Beginning",
  98: "Bunch Thinning",
  99: "Growth Percentage (%)",
  100: "Number of Blocks",
  101: "Final",
  102: "Grower Selection",
  103: "This Grower does not have any production ecuations in this season ",
  104: "Select Farm",
  105: "Estimated Export Boxes",
  106: "This Farm has no production ecuations in this season",
  107: "Blocks Status",
  108: "Labors",
  109: "Print",
  110: "Farm Map",
  111: "Block Progress",
  112: "You must create a dataset in MapBox to visualize layers in Farm ",
  113: "Background",
  114: "Without Background",
  115: "Satellite",
  116: "Layer",
  117: "Total Boxes",
  118: "Vines",
  119: "Progress %",
  120: "Detailed Progress",
  121: "Loading Layers",
  122: "Amplified Map",
  123: "There are no maps associated to this Farm",
  124: "Labors in Progress",
  125: "Stage",
  126: "Prog. Date",
  127: "Prog. Days",
  128: "Labor Start",
  129: "Map not found",
  130: "Go to Block",
  131: "See block",
  132: "There are no labors asociated to this farm",
  133: "Block/PE Stage",
  134: "Select Block",
  135: "Are you sure you want to create a block?",
  136: "This action cannot be undone",
  137: "No, Go Back",
  138: "Yes, Create it",
  139: "Variety - RootStock",
  140: "Year",
  141: "Real Vines / Block",
  142: "This Block has not been created to enter production ecuations this season ",
  143: "Edit",
  144: "Create Block",
  145: "for the season ",
  146: "Block Map",
  147: "To close the stage, you must create PE for this stage",
  148: "The stage was successfully closed.",
  149: "There was an error closing the stage.",
  150: "Previous Stage was recovered successfully.",
  151: "There was an error recovering the previous stage.",
  152: "The PE has been created successfully",
  153: "There was an error creating the PE.",
  154: "The PE has been updated successfully",
  155: "There was an error updating the PE",
  156: "Stage: ",
  157: "Go back to previous stage",
  158: "Close Stage ",
  159: "Edit PE Stage",
  160: "Create PE Stage",
  161: "Cancel",
  162: "Save",
  163: "Save PE",
  164: "Are you sure you want to close Stage ",
  165: "Are you sure you want to go back to Stage ",
  166: "This action cannot be undone; the PE of the current stage will be deleted",
  167: "Yes, close the stage",
  168: "Yes, go back to previous stage",
  169: "Updating the PE",
  170: "Create from the last PE",
  171: "Create from the real PE",
  172: "Refresh Control",
  173: "Deviation",
  174: "Export (%)",
  175: "Final (Closed)",
  176: "There is no data for this stage.",
  177: "Last",
  178: "Measured Vines",
  179: "Total Vines",
  180: "Burnt Vines",
  181: "Berries - Vines",
  182: "Berries measured by Vine",
  183: "Block Map ",
  184: "Select Stage",
  185: "Visualization Layer",
  186: "BUDS / VINE",
  187: "Forecasted Diameter",
  188: "Boxes",
  189: "Final Diameter",
  190: "Diam. Growth (%)",
  191: "Pounds",
  192: "All",
  193: "Labor Progress",
  194: "Elevation Angle",
  195: "Dimensions",
  196: "Block's Record/Background",
  197: "This Block has no map associated.",
  198: "The labor was eliminated successfully.",
  199: "There was an error eliminating the labor",
  200: "Programming Date",
  201: "Estimated Days",
  202: "Final Date",
  203: "Metrics from PE Stage",
  204: "Labor Detail",
  205: "Crop Adjustment Detail",
  206: "Describe the Labors",
  207: "Describe the Crop Adjustment",
  208: "Control Detail",
  209: "Describe the controls",
  210: "Create Labor",
  211: "Create Crop Adjustment",
  212: "Go Back",
  213: "Labor Summary",
  214: "Labor",
  215: "Progress/Measured",
  216: "Are you sure you want to delete ",
  217: "Yes, delete it",
  218: "Labor & Crop Adjustment",
  219: "Create Crop Adjustment",
  220: "The labor for the stage ",
  221: " was successfully updated",
  222: "There was an error creating the labor for the stage ",
  223: "Labor Detail",
  224: "Labor Type",
  225: "Main",
  226: "Crop Adjustment",
  227: "Main Labor Detail",
  228: "Crop Adjustment Detail",
  229: "Description",
  230: "Main Control Detail",
  231: "Crop Adjustment Control Detail ",
  232: "Labor Progress Map",
  233: "This Stage has no labors",
  234: "PEs Stages",
  235: "At this moment, there are no PEs in this season.",
  236: "Starting Date",
  237: "Labor (Previous PE & Crop Adjustment)",
  238: "Progress",
  239: "Control (Real PE)",
  240: "Measured",
  241: "Starting Prog. Date",
  242: "successfully created",
  243: "There was an error creating the variety ",
  244: "Add Variety",
  245: "Variety Name",
  246: "Exponent",
  247: "X Multiplier",
  248: "Lower Range",
  249: "Upper Range",
  250: "Create Variety",
  251: "Required Field",
  252: "Data successfully edited",
  253: "Created ",
  254: "No Records",
  255: "Details",
  256: "Variety Details",
  257: "DB Mantainer",
  258: "There was an error uploading the data...",
  259: "Customer",
  260: "created successfully",
  261: "There was an error creating the customer ",
  262: "Add Customer",
  263: "Customer Type",
  264: "1. Multi-Grower (Company)",
  265: "2. Mono-Grower Multi-Farm (Grower)",
  266: "3. Mono-Grower Mono-Farm (Farm)",
  267: "Starting Season",
  268: "Customer Deleted Successfully",
  269: "There was an error deleting the customer",
  270: "Invalid Date",
  271: "Not found",
  272: "Last Modification",
  273: "Customer Details",
  274: "The name can't be longer than 10 characters",
  275: "There was an error ",
  276: "creating the ",
  277: "Add",
  278: "Rootstock",
  279: "Inter Row Spacing",
  280: "Inter Vines Spacing",
  281: "Irrigation",
  282: "Planting Year",
  283: "Center Latitude",
  284: "Center Longitude",
  285: "Create",
  286: "deleted successfully",
  287: " erasing the ",
  288: "NO RECORD",
  289: " without Latitude/Longitude",
  290: "Block Detail: ",
  291: "Rootstock Details",
  292: "To ",
  293: "Address",
  294: "Information not provided",
  295: "Farm Details",
  296: "Grower Details",
  297: "Driving System Details",
  298: "System ",
  299: "Modification",
  300: "Name",
  301: "Exponent",
  302: "Metric",
  303: "Lower",
  304: "Normal",
  305: "Higher",
  306: "User",
  307: "ID",
  308: "Phone",
  309: "Role",
  310: "Metric System",
  311: "Imperial System",
  312: "Previous",
  313: "Edit & Close Stages",
  314: "Edit Only",
  315: "View Only",
  316: "Access Denied",
  317: "Select Customer",
  318: "There are no users associated to this Customer",
  319: "Average Bunch Weight",
  320: "Internal Market",
  321: "Theoretical Vines",
  322: "Programming",
  323: "Implementation",
  324: "Generate PE Stage",
  325: "The Block was initialized successfully",
  326: "There was an error initializing the block",
  327: "Surface",
  328: "over theoretical",
  329: "Real Vines",
  330: "was successfully created",
  331: "the labor ",
  332: " over the normal",
  333: " normal",
  334: " below the normal",
  335: "Range",
  336: "The stage was successfully closed",
  337: "PE",
  338: "Metric Guide",
  339: "Country",
  340: "Weight",
  341: "Box",
  342: "Distance",
  343: "Inches",
  344: "Milimeters",
  345: "Sign Out",
  346: "The Format of the ID number must have a dash character (-)",
  347: "Required",
  348: "Username",
  349: "Authorization",
  350: " updated successfully",
  351: " updating the ",
  352: "Authorization assigned for the ",
  353: "assigning authorization to the ",
  354: "Invitation resent correctly to:",
  355: "resending the invitation to the ",
  356: "Confirmed",
  357: "Awaiting password change",
  358: "Not confirmed",
  359: "Resend ",
  360: "invitation",
  361: "Enabled",
  362: "Yes",
  363: "Status",
  364: "Authorization",
  365: "There are no permissions associated to the User ",
  366: "Users",
  367: "Alarms",
  368: "Select Metric",
  369: "Comment",
  370: "Alarm",
  371: "Berry Weight",
  372: "ALARM DETAILS",
  373: "Every Variety",
  374: "Help",
  375: "Suckering",
  376: "Pergola",
  377: "Hectare (Ha)",
  378: "Estimated Profitability (US$)",
  379: "Price Conservative Scenario",
  380: "Price Expected Scenario",
  381: "Price Optimist Scenario",
  382: "Reference Price per Box",
  383: "Price per Kilo",
  384: "Other Incomes per Box",
  385: "Other Incomes per ",
  386: "Cost Until Harvest",
  387: "Was/Will be Harvested?",
  388: "Harvest & Box Cleaning Cost",
  389: "Was/Will be Packed/Packaged?",
  390: "Packaging & On Cold Cost per Box",
  391: "Other Costs per ",
  392: "Costs",
  393: "Scenarios",
  394: "Prices",
  395: "Incomes",
  396: "Profitability",
  397: "Profitability Parameters",
  398: "Georeferencing",
  399: "Edit Rows",
  400: "Edit Plants",
  401: "Total Boxes",
  402: "There was an error creating the user",
  403: "user",
  500: "My Profile",
  501: "Associated Farms",
  502: "Record/Background",
  503: "Line",
  504: "Row",
  505: "Quality",
  506: "Block Limit",
  507: "Other Stages",
  508: "Nº Plants Progress",
  509: "Nº Vines in Line",
  510: "Line Progress",
  511: "Total Pounds Internal Market",
};

export const translate = (id, language) => {
  if (language === "english") {
    return english[id];
  } else {
    return spanish[id];
  }
};

export const getEtapaHeader = (etapa, selectedLanguage, selectedMetric) => {
  switch (etapa) {
    case "Inicio":
      return translate(97, selectedLanguage);
    case "Poda":
      return translate(24, selectedLanguage);
    case "Arreglo":
      return translate(98, selectedLanguage);
    case "Fertilidad":
      return translate(6, selectedLanguage);
    case "Desbrota":
      return translate(375, selectedLanguage);
    case "Calibre":
      return translate(17, selectedLanguage);
    case "Final":
      return translate(101, selectedLanguage);
    case "Final (Cerrada)":
      return translate(175, selectedLanguage);

    case "Calidad Plantas":
      return translate(96, selectedLanguage);
    case "Yemas Plantas":
      return translate(186, selectedLanguage);
    case "Racimos Planta":
      return translate(8, selectedLanguage);
    case "Bayas Planta":
      return translate(181, selectedLanguage);
    case "Bayas Racimo":
      return translate(15, selectedLanguage);
    case "Calibre Envero":
      return translate(18, selectedLanguage);
    case "Calibre Proyectado":
      return translate(187, selectedLanguage);
    case "Cajas Há":
      return (
        translate(41, selectedLanguage) +
        " " +
        translateMetric(selectedMetric, selectedLanguage, true, 4)
      );
    case "Kilos Há":
      return (
        translateMetric(selectedMetric, selectedLanguage, false, 5) +
        " " +
        translateMetric(selectedMetric, selectedLanguage, true, 4)
      );
    case "Cajas Totales":
      return translate(117, selectedLanguage);
    case "% Exportación":
      return translate(174, selectedLanguage);
    case "Calibre Final":
      return translate(189, selectedLanguage);
    case "% Crecimiento Calibre":
      return translate(190, selectedLanguage);
    case "Kilos":
      return translate(191, selectedLanguage);
    case "Cargadores Planta":
      return translate(0, selectedLanguage);
    case "Yemas Cargador":
      return translate(1, selectedLanguage);
    case "Pitones Planta":
      return translate(2, selectedLanguage);
    case "Yemas Piton":
      return translate(3, selectedLanguage);
    case "Racimos Cargador":
      return translate(11, selectedLanguage);
    case "Racimos Piton":
      return translate(12, selectedLanguage);
    case "Programación":
      return translate(322, selectedLanguage);
    case "Ejecución":
      return translate(323, selectedLanguage);
    case "Generación EP Etapa":
      return translate(323, selectedLanguage);
    case "Final":
      return translate(101, selectedLanguage);
    case "Peso Baya":
      return translate(371, selectedLanguage);
    case "Yemas Há":
      return translate(5, selectedLanguage);
    case 0:
      return translate(97, selectedLanguage);
    case 1:
      return translate(24, selectedLanguage);
    case 2:
      return translate(6, selectedLanguage);
    case 3:
      return translate(10, selectedLanguage);
    case 4:
      return translate(98, selectedLanguage);
    case 5:
      return translate(17, selectedLanguage);
    case 6:
      return translate(101, selectedLanguage);
    default:
      return translate(97, selectedLanguage);
  }
};

export const translateProps = (label, selectedLanguage) => {
  switch (label) {
    case "Patrón":
      return translate(278, selectedLanguage);
    case "ID Sistema Conducción":
      return `ID ${translate(49, selectedLanguage)}`;
    case "Sistema de Conducción":
      return translate(49, selectedLanguage);
    case "Creación":
      return translate(253, selectedLanguage);
    case "Modificación":
      return translate(299, selectedLanguage);
    case "Nombre":
      return translate(300, selectedLanguage);
    case "Exponente":
      return translate(301, selectedLanguage);
    case "Multiplicador X":
      return translate(247, selectedLanguage);
    case "Rango Inferior":
      return translate(248, selectedLanguage);
    case "Rango Superior":
      return translate(249, selectedLanguage);
    case "ID Cuartel":
      return `ID ${translate(38, selectedLanguage)}`;
    case "Cuartel":
      return translate(38, selectedLanguage);
    case "Predio":
      return translate(37, selectedLanguage);
    case "Variedad":
      return translate(35, selectedLanguage);
    case "Riego":
      return translate(281, selectedLanguage);
    case "Georeferenciación":
      return translate(398, selectedLanguage);
    case "ID Predio":
      return `ID ${translate(37, selectedLanguage)}`;
    case "Productor":
      return translate(36, selectedLanguage);
    case "Cliente":
      return translate(259, selectedLanguage);
    case "ID":
      return "ID";
    case "ID Cliente":
      return `ID ${translate(259, selectedLanguage)}`;
    case "Tipo Cliente":
      return translate(263, selectedLanguage);
    case "ID Patrón":
      return `ID ${translate(278, selectedLanguage)}`;
    case "Métrica":
      return translate(302, selectedLanguage);
    case "Bajo Rango Superior":
      return `${translate(303, selectedLanguage)} ${translate(
        249,
        selectedLanguage
      )}`;
    case "Normal Rango Superior":
      return `${translate(304, selectedLanguage)} ${translate(
        249,
        selectedLanguage
      )}`;
    case "Alto Rango Superior":
      return `${translate(305, selectedLanguage)} ${translate(
        249,
        selectedLanguage
      )}`;
    case "Usuario":
      return translate(306, selectedLanguage);
    case "Rut":
      return translate(307, selectedLanguage);
    case "E-mail":
      return "Email";
    case "Teléfono":
      return translate(308, selectedLanguage);
    case "Rol":
      return translate(309, selectedLanguage);
    case "Super Admin":
      return "Super Admin";
    case "Empresa Admin":
      return `${translate(43, selectedLanguage)} Admin`;
    case "Empresa Editor":
      return `${translate(43, selectedLanguage)} Editor`;
    case "Empresa sólo Ver":
      return `${translate(43, selectedLanguage)} ${translate(
        315,
        selectedLanguage
      )}`;
    case "Parrón":
      return translate(376, selectedLanguage);
    default:
      return "";
  }
};

export const getRouteName = (route, selectedLanguage) => {
  switch (route) {
    case "Inicio":
      return translate(44, selectedLanguage);

    default:
      break;
  }
};

export const converter = {
  inches: {
    14.288: "9/16",
    15.875: "5/8",
    17.463: "11/16",
    19.05: "3/4",
    20.638: "13/16",
    22.225: "7/8",
    23.813: "15/16",
    25.4: "1",
    28.575: "1 1/8",
    31.75: "1 1/4 ",
  },
  metricToImperial: {
    mmToIn: (val) => {
      // console.log(`val: ${val}`);
      // array con milimetros:
      let mms = Object.keys(converter.inches);
      // console.log(`mms: ${mms}`);
      // reduce: if abs value of current < abs value of prev
      // return current else return prev
      let closestMatch = mms.reduce((prev, current) => {
        return Math.abs(current - val) < Math.abs(prev - val) ? current : prev;
      });
      // console.log(`closestMatch: ${closestMatch}`);
      // return converter.inches[closestMatch];
    },
    cmToFt: (val) => val * 0.0328084,
    mToFt: (val) => val * 3.28084,
    kmToMi: (val) => val * 0.621371,
    haToAc: (val) => val * 2.47105,
    kgToLb: (val) => val * 2.20462,
    grToOz: (val) => val * 0.035274,
    kgHaToLbAc: (val) => val * 0.892179,
    kgBoxToLbBox: (val) => {
      let totalKgs = val * 8.2;
      let totalLbs = totalKgs * 2.20462;
      let lbBoxes = totalLbs / 19;
      return lbBoxes;
    },
    rgBoxToZeBox: (val) => val * 1.82222,
    abbreviationsEnglish: {
      0: "in",
      1: "ft",
      2: "ft",
      3: "mi",
      4: "Ac",
      5: "lb",
      6: "oz",
      7: "Ac",
    },
    abbreviationsSpanish: {
      0: "mm",
      1: "cm",
      2: "m",
      3: "Kms",
      4: "Ha",
      5: "Kgs",
      6: "grs",
    },
    labelsEnglish: {
      0: "Inches",
      1: "Foot",
      2: "Feet",
      3: "Miles",
      4: "Acres",
      5: "Pounds",
      6: "Ounces",
      7: "Acres",
    },
    labelsSpanish: {
      0: "Milimetros",
      1: "Centimetro",
      2: "Metros",
      3: "Kilometros",
      4: "Hectáreas",
      5: "Kilogramos",
      6: "Gramos",
      7: "Hectares",
    },
  },
  metric: {
    abbreviationsSpanish: {
      0: "mm",
      1: "cm",
      2: "m",
      3: "Kms",
      4: "Ha",
      5: "Kgs",
      6: "grs",
      7: "Ha",
    },
    abbreviationsEnglish: {
      0: "mm",
      1: "cm",
      2: "m",
      3: "Kms",
      4: "Ha",
      5: "Kgs",
      6: "grs",
    },
    labelsSpanish: {
      0: "Milimetros",
      1: "Centimetro",
      2: "Metros",
      3: "Kilometros",
      4: "Hectáreas",
      5: "Kilogramos",
      6: "Gramos",
      7: "Hectares",
    },
    labelsEnglish: {
      0: "Milimeters",
      1: "Centimeter",
      2: "Meters",
      3: "Kilometers",
      4: "Hectares",
      5: "Kilograms",
      6: "Grams",
    },
  },
};

export const translateMetric = (
  selectedMetric,
  selectedLanguage,
  abbreviated,
  id
) => {
  // console.log("abbreviated", abbreviated, id);
  if (
    selectedMetric === "us" &&
    selectedLanguage === "english" &&
    abbreviated === true
  ) {
    // console.log(
    //   "translatedMetric: ",
    //   converter.metricToImperial.abbreviationsEnglish[id]
    // );
    return converter.metricToImperial.abbreviationsEnglish[id];
  } else if (
    selectedMetric === "us" &&
    abbreviated === false &&
    selectedLanguage === "english"
  ) {
    // console.log(
    //   "translatedMetric: ",
    //   converter.metricToImperial.labelsEnglish[id]
    // );
    return converter.metricToImperial.labelsEnglish[id];
  } else if (
    selectedMetric !== "us" &&
    abbreviated === true &&
    selectedLanguage === "spanish"
  ) {
    // console.log(
    //   "translatedMetric: ",
    //   converter.metric.abbreviationsSpanish[id]
    // );
    return converter.metric.abbreviationsSpanish[id];
  } else if (
    selectedMetric !== "us" &&
    abbreviated === false &&
    selectedLanguage === "spanish"
  ) {
    // console.log(
    //   "converter.metric.labelsSpanish[id]",
    //   converter.metric.labelsSpanish[id]
    // );
    return converter.metric.labelsSpanish[id];
  } else if (
    selectedMetric === "us" &&
    abbreviated === true &&
    selectedLanguage === "spanish"
  ) {
    // console.log(
    //   "converter.metric.labelsSpanish[id]",
    //   converter.metricToImperial.abbreviationsEnglish[id]
    // );
    return converter.metricToImperial.abbreviationsEnglish[id];
  } else if (
    selectedMetric !== "us" &&
    abbreviated === true &&
    selectedLanguage === "english"
  ) {
    // console.log(
    //   "converter.metric.abbreviationsEnglish[id]",
    //   converter.metric.abbreviationsEnglish[id]
    // );
    return converter.metric.abbreviationsEnglish[id];
  } else if (
    selectedMetric === "us" &&
    abbreviated === false &&
    selectedLanguage === "spanish"
  ) {
    return converter.metricToImperial.labelsEnglish[id];
  } else if (
    selectedMetric !== "us" &&
    abbreviated === false &&
    selectedLanguage === "english"
  )
    return converter.metric.labelsEnglish[id];
};

// export const translateTextMetrics = (id, language, metric) => {
//   if (language === "english" && metric === 'us') {
//     return english[id];
//   } else if (language === "english" && metric === 'ze') {
//     return `${english[id]} `southAfricanMetric[id]
//   }

//   else {
//     return spanish[id];
//   }
// }

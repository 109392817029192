import React from "react";

import async from "../components/Async";

// icons
import {
  Layout,
  Copy,
  Monitor,
  Home,
  Bell,
  Settings,
  // Clipboard,
  // Activity,
  ShoppingBag,
  BookOpen,
  List,
  Database,
  Users,
  Trello,
  HelpCircle,
} from "react-feather";
import { useReactiveVar } from "@apollo/client";

import { selectedLanguage } from "../graphql/localstate/localState";
import { translate } from "../globalfunctions/translation";

//const Landing = async(() => import("../pages/presentation/Landing"));

const language = selectedLanguage();
// console.log(`language from index2: ${language}`);

const PlayGround = async(() => import("../pages2/playGround/Playground"))

const Inicio = async(() => import("../pages2/inicio/Inicio"));

//const Labores = async(() => import("../pages2/labores/Labores"));
const Empresa = async(() => import("../pages2/empresa/Empresa"));
const Predio = async(() => import("../pages2/predio/Predio"));
const Productor = async(() => import("../pages2/productor/Productor"));
const MiCuenta = async(() => import("../pages2/mi_cuenta/MiCuenta"));
const MonitorEP = async(() => import("../pages2/monitor/MonitorEP"));

//const AntecedentesCuartel = async(() => import("../pages2/cuartel/AntecedentesCuartel"));
const Cuartel = async(() => import("../pages2/cuartel/Cuartel"));
//const EPTemporadaCuartel = async(() => import("../pages2/cuartel/EPTemporadaCuartel"));

//const AntecedentesPredio = async(() => import("../pages2/predio/AntecedentesPredio"));
//const ProduccionPredio = async(() => import("../pages2/predio/ProduccionPredio"));
//const EPEtapaPredio = async(() => import("../pages2/predio/EPEtapaPredio"));
//const EPTemporadaPredio = async(() => import("../pages2/predio/EPTemporadaPredio"));

const AlarmasRecientes = async(() =>
  import("../pages2/alarmas/AlarmasRecientes")
);
const DetalleAlarmaComponent = async(() =>
  import("../pages2/alarmas/DetalleAlarmaComponent")
);
//const AlarmasAdministracion = async(() => import("../pages2/alarmas/AlarmasAdministracion"));

//Mantenedor BD
const MantenedorVariedad = async(() =>
  import("../pages2/mantenedorbd/MantenedorVariedad")
);
const MantenedorCuarteles = async(() =>
  import("../pages2/mantenedorbd/MantenedorCuarteles")
);
const MantenedorClientes = async(() =>
  import("../pages2/mantenedorbd/MantenedorClientes")
);
const MantenedorProductores = async(() =>
  import("../pages2/mantenedorbd/MantenedorProductores")
);
const MantenedorPredios = async(() =>
  import("../pages2/mantenedorbd/MantenedorPredios")
);
const MantenedorSistemasConduccion = async(() =>
  import("../pages2/mantenedorbd/MantenedorSistemasConduccion")
);
const MantenedorPatrones = async(() =>
  import("../pages2/mantenedorbd/MantenedorPatrones")
);

const DetalleClienteComponent = async(() =>
  import("../pages2/mantenedorbd/clientes/DetalleClienteComponent")
);
const DetalleProductorComponent = async(() =>
  import("../pages2/mantenedorbd/productores/DetalleProductorComponent")
);
const DetallePredioComponent = async(() =>
  import("../pages2/mantenedorbd/predios/DetallePredioComponent")
);
const DetalleSistemaComponent = async(() =>
  import("../pages2/mantenedorbd/sistemas_conduccion/DetalleSistemaComponent")
);
const DetallePatronComponent = async(() =>
  import("../pages2/mantenedorbd/patrones/DetallePatronComponent")
);
const DetalleVariedad = async(() =>
  import("../pages2/mantenedorbd/variedades/DetalleVariedad")
);
const DetalleCuartelComponent = async(() =>
  import("../pages2/mantenedorbd/cuarteles/DetalleCuartelComponent")
);

// const MantenedorBD = async(() => import("../pages2/administracion/MantenedorBD"));
// const MantenedorCuentas = async(() => import("../pages2/administracion/MantenedorCuentas"));
const MantenedorUsuarios = async(() =>
  import("../pages2/usuarios/MantenedorUsuarios")
);
const DetalleUsuarioComponent = async(() =>
  import("../pages2/usuarios/DetalleUsuarioComponent")
);
//const MonitorDatos = async(() => import("../pages2/administracion/MonitorDatos"));

// Documentation
const Welcome = async(() => import("../pages2/docs/Welcome"));
const GettingStarted = async(() => import("../pages2/docs/GettingStarted"));
const EnvironmentVariables = async(() =>
  import("../pages2/docs/EnvironmentVariables")
);
const Deployment = async(() => import("../pages2/docs/Deployment"));
const Theming = async(() => import("../pages2/docs/Theming"));
const StateManagement = async(() => import("../pages2/docs/StateManagement"));
const APICalls = async(() => import("../pages2/docs/APICalls"));
const ESLintAndPrettier = async(() =>
  import("../pages2/docs/ESLintAndPrettier")
);
const Support = async(() => import("../pages2/docs/Support"));
const Changelog = async(() => import("../pages2/docs/Changelog"));
const MetricGuide = async(() => import("../pages2/ayuda/GuiaMetricas"));

// georeferenciacion
const Georeferenciacion = async(() =>
  import("../pages2/georef/Georeferenciacion")
);

const EditHileras = async(() => import("../pages2/georef/edit/EditHileras"));
const EditPlantas = async(() => import("../pages2/georef/edit/EditPlantas"));

const playGround = {
  id: "playGround",
  path: "/playGround",
  header: "",
  icon: <Home />, // antes estaba antecedentes cuartel
  component: PlayGround,
  children: null,
}

const landingRoutes = {
  id: "Inicio",
  path: "/",
  header: "",
  icon: <Home />, // antes estaba antecedentes cuartel
  component: Inicio,
  children: null,
};

const productorRoutes = {
  id: "Productor",
  path: "/productor",
  header: "",
  icon: <Trello />,
  containsHome: true,
  component: Productor,
  children: null,
};

const predioRoutes = {
  id: "Predio",
  path: "/predio",
  header: "",
  icon: <Layout />,
  containsHome: false,
  component: Predio,
};

const cuartelRoutes = {
  id: "Cuartel",
  path: "/cuartel",
  header: "",
  icon: <Copy />,
  containsHome: true,
  component: Cuartel,
};

const alarmasRoutes = {
  id: "Rangos Métricas EP",
  path: "/alarmas",
  icon: <Bell />,
  badge: "",
  containsHome: false,
  children: null,
  component: AlarmasRecientes,
};

const alarmaDetalle = {
  id: "Alarma Detalle",
  path: "/alarmas/detalle",
  icon: <Bell />,
  badge: "",
  containsHome: false,
  children: null,
  component: DetalleAlarmaComponent,
};

const empresaRoutes = {
  id: "Empresa",
  path: "/empresa",
  header: "",
  icon: <ShoppingBag />,
  containsHome: false,
  component: Empresa,
  children: null,
};

// const helpRoutes = {
//   id: "Guía Métricas",
//   path: "/guiaMetricas",
//   header: "",
//   icon: <HelpCircle />,
//   containsHome: false,
//   component: MetricGuide,
//   children: null,
// };

const helpRoutes = {
  id: "Ayuda",
  path: "/ayuda",
  icon: <HelpCircle />,
  containsHome: false,
  children: [
    {
      path: "/guiaMetricas",
      name: "Info Métricas",
      component: MetricGuide,
    },
  ],
};

const mantenedorbdRoutes = {
  id: "Mantenedor BD",
  header: "Administración",
  path: "/mantenedorbd",
  icon: <Database />,
  containsHome: false,
  children: [
    {
      path: "/mantenedorbd/clientes",
      name: "Clientes",
      component: MantenedorClientes,
    },
    {
      path: "/mantenedorbd/clientes/detalle",
      name: null,
      component: DetalleClienteComponent,
    },
    {
      path: "/mantenedorbd/productores",
      name: "Productores",
      component: MantenedorProductores,
    },
    {
      path: "/mantenedorbd/productores/detalle",
      name: null,
      component: DetalleProductorComponent,
    },
    {
      path: "/mantenedorbd/predios",
      name: "Predios",
      component: MantenedorPredios,
    },
    {
      path: "/mantenedorbd/predios/detalle",
      name: null,
      component: DetallePredioComponent,
    },
    {
      path: "/mantenedorbd/cuarteles",
      name: "Cuarteles",
      component: MantenedorCuarteles,
    },
    {
      path: "/mantenedorbd/cuarteles/detalle",
      name: null,
      component: DetalleCuartelComponent,
    },
    {
      path: "/mantenedorbd/variedades",
      name: "Variedades",
      component: MantenedorVariedad,
    },
    {
      path: "/mantenedorbd/variedades/detalle",
      name: null,
      component: DetalleVariedad,
    },
    {
      path: "/mantenedorbd/patrones",
      name: "Patrones",
      component: MantenedorPatrones,
    },
    {
      path: "/mantenedorbd/patrones/detalle",
      name: null,
      component: DetallePatronComponent,
    },
    {
      path: "/mantenedorbd/sistemas",
      name: "Sistemas de Conducción",
      component: MantenedorSistemasConduccion,
    },
    {
      path: "/mantenedorbd/sistemas/detalle",
      name: null,
      component: DetalleSistemaComponent,
    },
  ],
  component: null,
};

const logdatosRoutes = {
  id: "Cargas de Datos",
  path: "/logdatos",
  header: "",
  icon: <Monitor />,
  containsHome: false,
  component: MonitorEP,
  children: null,
};

const adminusuariosRoutes = {
  id: "Usuarios Empresas",
  path: "/adminusuarios",
  header: "",
  icon: <Users />,
  containsHome: false,
  //component: MonitorEP,
  component: MantenedorUsuarios,
  children: null,
};
const adminusuariosDetalle = {
  id: "Detalle Usuario",
  path: "/adminusuarios/detalle",
  icon: <Bell />,
  badge: "",
  containsHome: false,
  children: null,
  component: DetalleUsuarioComponent,
};

const documentationRoutes = {
  id: "Documentación",
  path: "/documentation",
  header: translate(54, language),
  icon: <BookOpen />,
  children: [
    {
      path: "/documentation/welcome",
      name: "Bienvenido",
      component: Welcome,
    },
    {
      path: "/documentation/getting-started",
      name: "Empezando",
      component: GettingStarted,
    },
    {
      path: "/documentation/environment-variables",
      name: "Variables de Entorno",
      component: EnvironmentVariables,
    },
    {
      path: "/documentation/deployment",
      name: "Despliegue",
      component: Deployment,
    },
    {
      path: "/documentation/theming",
      name: "Temas",
      component: Theming,
    },
    {
      path: "/documentation/state-management",
      name: "Manejo de estado",
      component: StateManagement,
    },
    {
      path: "/documentation/api-calls",
      name: "LLamadas API",
      component: APICalls,
    },
    {
      path: "/documentation/eslint-and-prettier",
      name: "ESLint & Prettier",
      component: ESLintAndPrettier,
    },
    {
      path: "/documentation/support",
      name: "Apoyo",
      component: Support,
    },
  ],
  component: null,
};

const changelogRoutes = {
  id: "Versión Actual",
  path: "/changelog",
  badge: "v1.05",
  icon: <List />,
  component: Changelog,
  children: null,
};

const miCuentaRoutes = {
  id: "Mi Cuenta",
  path: "/mi_cuenta",
  header: "",
  icon: null,
  //component: MonitorEP,
  component: MiCuenta,
  children: null,
};
const geoRefRoutes = {
  id: "Georef",
  path: "/georef/:cuartelId",
  component: Georeferenciacion,
  children: null,
};

const editPlantasRoute = {
  id: "EditPlantas",
  path: "/georef/editar/plantas/:cuartelId",
  component: EditPlantas,
  children: null,
};

const editHilerasRoute = {
  id: "EditHileras",
  path: "/georef/editar/hileras/:cuartelId",
  component: EditHileras,
  children: null,
};

export const dashboardLayoutRoutes2 = [
  landingRoutes,
  empresaRoutes,
  productorRoutes,
  predioRoutes,
  cuartelRoutes,
  documentationRoutes,
  changelogRoutes,
  miCuentaRoutes,
  helpRoutes,
];

export const superAdminDashboardLayoutRoutes = [
  playGround,
  landingRoutes,
  empresaRoutes,
  productorRoutes,
  predioRoutes,
  cuartelRoutes,
  mantenedorbdRoutes,
  alarmasRoutes,
  alarmaDetalle,
  adminusuariosRoutes,
  adminusuariosDetalle,
  //documentationRoutes,
  changelogRoutes,
  miCuentaRoutes,
  helpRoutes,
  geoRefRoutes,
  editPlantasRoute,
  editHilerasRoute,
];

export const presentationLayoutRoutes = [landingRoutes];

export const sidebarRoutes = [
  landingRoutes,
  empresaRoutes,
  productorRoutes,
  predioRoutes,
  cuartelRoutes,
  mantenedorbdRoutes,
  alarmasRoutes,
  adminusuariosRoutes,
  changelogRoutes,
  helpRoutes,
];

export const superAdminRoutes = [
  landingRoutes,
  empresaRoutes,
  productorRoutes,
  predioRoutes,
  cuartelRoutes,
  mantenedorbdRoutes,
  alarmasRoutes,
  //alarmaDetalle,
  adminusuariosRoutes,
  //adminusuariosDetalle,
  //documentationRoutes,
  changelogRoutes,
  helpRoutes,
];

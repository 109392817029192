import * as d3 from "d3";
import moment from "moment";
import "moment/locale/es";
import { nest } from "d3-collection";

import { useReactiveVar } from "@apollo/client";
import {
  selectedLanguage,
  selectedMetric,
} from "../graphql/localstate/localState";
import { translate, translateMetric, getEtapaHeader } from "./translation";

let idioma = selectedLanguage;


export function NombreTemporada(numTemp) {
  switch (numTemp) {
    case 1:
      return 2010;
    case 2:
      return 2020;
    case 3:
      return 2021;
    case 4:
      return 2022;
    case 5:
      return 2023;
    case 6:
      return 2024;
  }
}

export function EtapaEpGlosa( numEtapa, selectedLanguage ) {
  
  switch (numEtapa) {
    case 0:
      return `${translate(97, selectedLanguage)}`; // Inicio
    case 1:
      return `${translate(24, selectedLanguage)}`; // Poda
    case 2:
      return `${translate(6, selectedLanguage)}`; // Fertilidad
    case 3:
      return `${translate(10, selectedLanguage)}`; // Desbrota
    case 4:
      return  `${translate(98, selectedLanguage)}`; // Arreglo
    case 5:
      return `${translate(17, selectedLanguage)}`; // Calibre
    case 6:
      return `${translate(101, selectedLanguage)}`; // Final
    case 7:
      return `${translate(175, selectedLanguage)}`; // Final (Cerrada)
    default:
      return "";
  }
}

export function TipoEpGlosa(num) {
  switch (num) {
    case 0:
      return "Anterior";
    case 1:
      return "Real";
    case 2:
      return "Etapa";
    default:
      return "nn";
  }
}

export function getClienteFromUserGroup(user_group) {
  const aux = parseInt(
    user_group.substring(
      user_group.indexOf("_") + 1,
      user_group.lastIndexOf("_") - 4
    )
  );

  return aux;
}

export function diff_dates(date1, date2) {
  if (date1 == "" || date2 == "") return "";

  const aux_date1 = moment(date1);

  const aux_date2 = moment(date2);

  if (aux_date1 == "Fecha Inválida" || aux_date2 == "Fecha Inválida") return "";

  return aux_date2.diff(aux_date1, "days");
}

export function formatd3(metrica, valor) {
  const format1 = ".1f";
  const format2 = ".2f";
  const format0 = ".0f";
  const format_entero = ",.0f";
  const rounded_perc = ".0%";

  // //console.log("metrica ",metrica)
  if (metrica === undefined) return "";
  if (metrica == "r_etapa_actual") return EtapaEpGlosa(valor);
  if (metrica === "string") return valor;
  if (
    metrica == "modificacion" ||
    metrica == "fecha_inicio_prog" ||
    metrica == "fecha_fin_prog"
  ) {
    if (valor == "" || valor === null || valor === undefined) return "";

    const aux_date = moment(valor).format("DD-MM-YYYY");
    if (aux_date != "Fecha inválida") return aux_date;
    else return "";
  }

  if (valor == "") return "";

  if (valor == null) return "";

  if (isNaN(valor)) return "";

  if (metrica == "r_ep_anterior_real_etapa") return TipoEpGlosa(valor);

  const aux = {
    nn: format_entero,
    antec_plantas_hectareas: format_entero,
    antec_calidad_planta: format1,
    antec_hectareas: format2,
    teoricas_ha: format_entero,
    teoricas_cuartel: format_entero,
    teoricas_totales: format_entero,
    num_cuarteles: format0,
    poda_c_carga_planta: format1,
    poda_c_yemas_carga: format1,
    poda_c_piton_planta: format1,
    poda_c_yemas_piton: format1,
    poda_d1_yemas_planta: format0,
    poda_d2_yemas_ha: format_entero,
    fert_c_fertilizacion_yemas: format0,
    fert_d1_racimos_planta: format_entero,
    fert_d2_racimos_ha: format_entero,
    desb_c_racimos_carga: format1,
    desb_c_racimos_piton: format1,
    desb_d1_racimos_planta: format0,
    desb_d2_racimos_ha: format_entero,
    arr_c_racimos_planta: format1,
    arr_c_bayas_racimo: format1,
    arr_d1_racimos_ha: format_entero,
    arr_d2_bayas_ha: format_entero,
    cal_c_calibre_envero: format1,
    cal_c_porcentaje_crecimiento: format1,
    cal_calibre_promedio: format1,
    cal_peso_baya: format1,
    cal_d1_peso_racimo: format1,
    prod_kilos_hectareas: format_entero,
    prod_c_exportacion: format0,
    prod_d1_cajas_hectareas: format_entero,
    prod_d2_cajas_totales: format_entero,
    prod_d1_kilos_mercado_interno: format_entero,
    rentabilidad: format_entero,
    // beta
    final_porc_crecimiento: format1,
    final_calibre_promedio: format1,
    final_peso_baya_promedio: format1,
    final_peso_baya_promedio: format1,
    final_kilos_ha: format_entero,
    final_porc_exportacion: format1,
    final_cajas_totales: format_entero,
    final_kilos_mercado_interno: format_entero,
  };

  // //console.log("formato Metrica: ", metrica+" -- "+aux[metrica])

  const format = d3.format(aux[metrica]);

  // const format = d3.format(format_entero)

  return format(valor);
}

export function TipoRecoleccionEtapa(numEtapa) {
  switch (numEtapa) {
    case 1:
      return 1;
    case 2:
      return 0;
    case 3:
      return 2;
    case 4:
      return 3;
    case 5:
      return 0;
    case 6:
      return 0;
    default:
      return 0;
  }
}

export function getEmptyEP() {
  return {
    id: "",
    antec_plantas_hectareas: "",
    antec_calidad_planta: "",
    antec_hectareas: "",
    antec_distancia_sobre: "",
    antec_distancia_entre: "",

    poda_c_carga_planta: "",
    poda_c_yemas_carga: "",
    poda_c_piton_planta: "",
    poda_c_yemas_piton: "",
    poda_d1_yemas_planta: "",
    poda_d2_yemas_ha: "",

    fert_c_fertilizacion_yemas: "",
    fert_d1_racimos_planta: "",
    fert_d2_racimos_ha: "",

    desb_c_racimos_carga: "",
    desb_c_racimos_piton: "",
    desb_d1_racimos_planta: "",
    desb_d2_racimos_ha: "",

    arr_c_racimos_planta: "",
    arr_c_bayas_racimo: "",
    arr_d2_bayas_ha: "",

    cal_c_calibre_envero: "",
    cal_c_porcentaje_crecimiento: "",
    cal_calibre_promedio: "",
    cal_peso_baya: "",

    prod_kilos_hectareas: "",
    prod_c_exportacion: "",
    prod_d1_cajas_hectareas: "",
    prod_d2_cajas_totales: "",
    prod_d1_kilos_mercado_interno: "",
    modificacion: "",
  };
}

{
  /*    
export function getMetricasDerivadasNew(aux,etapa) {
  
  if (etapa != 6) return getMetricasDerivadas(aux)
  else {
     getMetricasDerivadasEtapa6(aux)
    
  }
  
}   
*/
}
export function getMetricasDerivadas(aux) {
  // console.log("aux antes,...,", aux);

  let aux2 = {};

  aux2.poda_d1_yemas_planta =
    aux.poda_c_carga_planta * aux.poda_c_yemas_carga +
    aux.poda_c_piton_planta * aux.poda_c_yemas_piton;
  aux2.poda_d2_yemas_ha =
    aux2.poda_d1_yemas_planta * aux.antec_plantas_hectareas;

  aux2.fert_d1_racimos_planta = roundToDec(
    (aux.fert_c_fertilizacion_yemas / 100) * aux2.poda_d1_yemas_planta
  );
  aux2.fert_d2_racimos_ha =
    aux.antec_plantas_hectareas * aux2.fert_d1_racimos_planta;
  //aux2.fert_c_fertilizacion_yemas = (aux2.fert_d1_racimos_planta / aux2.poda_d1_yemas_planta) * 100

  aux2.desb_d1_racimos_planta =
    aux.desb_c_racimos_carga * aux.poda_c_carga_planta +
    aux.desb_c_racimos_piton * aux.poda_c_piton_planta;
  aux2.desb_d2_racimos_ha =
    aux.antec_plantas_hectareas * aux2.desb_d1_racimos_planta;

  aux2.arr_d1_racimos_ha =
    aux.arr_c_racimos_planta * aux.antec_plantas_hectareas;

  aux2.arr_d2_bayas_ha =
    aux.arr_c_bayas_racimo *
    aux.arr_c_racimos_planta *
    aux.antec_plantas_hectareas;

  // aux2.prod_kilos_hectareas = aux.cal_peso_baya * aux2.arr_d2_bayas_ha
  aux2.cal_calibre_promedio =
    aux.cal_c_calibre_envero * (1 + aux.cal_c_porcentaje_crecimiento / 100);
  aux2.cal_peso_baya = aux.cal_peso_baya;
  aux2.cal_d1_peso_racimo = aux.cal_peso_baya * aux.arr_c_bayas_racimo;

  if (aux.k_etapa == 6) {
    aux2.prod_kilos_hectareas = aux.prod_kilos_hectareas;
    // calculo para kilos mercado interno
    aux2.prod_d1_kilos_mercado_interno = Math.round(
      aux2.prod_kilos_hectareas *
        aux.antec_hectareas *
        (1 - aux.prod_c_exportacion / 100)
    );
    // aux2.prod_d1_kilos_mercado_interno = Math.round(
    //   aux2.prod_kilos_hectareas *
    //     aux.antec_hectareas *
    //     (1 - aux.prod_c_exportacion)
    // );
  } else {
    aux2.prod_kilos_hectareas = Math.round(
      (aux2.cal_peso_baya * aux2.arr_d2_bayas_ha) / 1000
    );
  }
  // aux2.prod_c_exportacion=80
  const ex = aux.prod_c_exportacion / 100;

  aux2.prod_d1_cajas_hectareas = Math.round(
    (aux2.prod_kilos_hectareas * ex) / 8.4
  );
  aux2.prod_d2_cajas_totales = Math.round(
    aux2.prod_d1_cajas_hectareas * aux.antec_hectareas
  );
  aux2.prod_d1_kilos_mercado_interno = Math.round(
    aux2.prod_kilos_hectareas * aux.antec_hectareas * (1 - ex)
  );

  // console.log("aux2 despues,...,", aux2);

  return aux2;
}

export function getMetricasDerivadasEtapa6(aux) {
  let aux2 = {};

  aux2.poda_d1_yemas_planta =
    aux.poda_c_carga_planta * aux.poda_c_yemas_carga +
    aux.poda_c_piton_planta * aux.poda_c_yemas_piton;
  aux2.poda_d2_yemas_ha =
    aux2.poda_d1_yemas_planta * aux.antec_plantas_hectareas;

  aux2.fert_d1_racimos_planta = roundToDec(
    (aux.fert_c_fertilizacion_yemas / 100) * aux2.poda_d1_yemas_planta
  );
  aux2.fert_d2_racimos_ha =
    aux.antec_plantas_hectareas * aux2.fert_d1_racimos_planta;
  //aux2.fert_c_fertilizacion_yemas = (aux2.fert_d1_racimos_planta / aux2.poda_d1_yemas_planta) * 100

  aux2.desb_d1_racimos_planta =
    aux.desb_c_racimos_carga * aux.poda_c_carga_planta +
    aux.desb_c_racimos_piton * aux.poda_c_piton_planta;
  aux2.desb_d2_racimos_ha =
    aux.antec_plantas_hectareas * aux2.desb_d1_racimos_planta;

  aux2.arr_d1_racimos_ha =
    aux.arr_c_racimos_planta * aux.antec_plantas_hectareas;
  aux2.arr_d2_bayas_ha =
    aux.arr_c_bayas_racimo *
    aux.arr_c_racimos_planta *
    aux.antec_plantas_hectareas;

  // aux2.prod_kilos_hectareas = aux.cal_peso_baya * aux2.arr_d2_bayas_ha
  aux2.cal_calibre_promedio =
    aux.cal_c_calibre_envero * (1 + aux.cal_c_porcentaje_crecimiento / 100);
  aux2.cal_peso_baya = aux.cal_peso_baya;
  aux2.cal_d1_peso_racimo = aux.cal_peso_baya * aux.arr_c_bayas_racimo;

  aux2.prod_kilos_hectareas = aux.prod_kilos_hectareas;
  // aux2.prod_c_exportacion=80
  const ex = aux.prod_c_exportacion / 100;

  aux2.prod_d1_cajas_hectareas = Math.round(
    (aux2.prod_kilos_hectareas * ex) / 8.4
  );
  aux2.prod_d2_cajas_totales = Math.round(
    aux2.prod_d1_cajas_hectareas * aux.antec_hectareas
  );
  aux2.prod_d1_kilos_mercado_interno = Math.round(
    aux2.prod_kilos_hectareas * aux.antec_hectareas * (1 - ex)
  );

  return aux2;
}

export function getMetricasDerivadasFinal(aux, aux_final) {
  let aux2 = {};
  // console.log('##################### GLOBAL FUNCS ###############################')
  //  console.log(`aux inicial: `, aux)
  // console.log(`aux final: `, aux_final)

  aux2.final_porc_crecimiento =
    (100 *
      (aux_final.final_calibre_promedio - aux_final.final_calibre_envero)) /
    aux_final.final_calibre_envero;

  aux2.final_porc_exportacion =
    (100 * aux_final.final_cajas_totales * 8.4) /
    (aux_final.final_cajas_totales * 8.4 +
      aux_final.final_kilos_mercado_interno);

  // console.log("aux en getMetricasDerivadasFinal, ", aux)
  //  console.log("aux.antece_hectareas en getMetricasDerivadas, ", aux.antec_hectareas)

  aux2.final_kilos_ha =
    (aux_final.final_cajas_totales * 8.4 +
      aux_final.final_kilos_mercado_interno) /
    aux.antec_hectareas;

  aux2.final_peso_racimo =
    aux_final.final_peso_baya_promedio * aux.arr_c_bayas_racimo;

  //  aux2.final_peso_baya_promedio =  aux2.final_peso_racimo / aux.arr_c_bayas_racimo

  // aux2.final_peso_baya_promedio =  aux.cal_peso_baya

  // console.log("aux_final 66", aux_final)
  //console.log("aux2 66", aux2)

  return aux2;
}

export function getDataHeaderIndentedTree() {
  let data_headers = {
    name: "(1) Inicio",
    ind: 0,
    tipo: "etapa",
    var: "nn",
    children: [
      {
        name: "Plantas Há",
        ind: 1,
        tipo: "hoja2",
        var: "antec_plantas_hectareas",
      },
      {
        name: "(2) Poda",
        ind: 2,
        tipo: "etapa",
        var: "nn",
        children: [
          {
            name: "Cargadores Planta",
            ind: 3,
            tipo: "hoja2",
            var: "poda_c_carga_planta",
          },
          {
            name: "Yemas Cargador",
            ind: 4,
            tipo: "hoja2",
            var: "poda_c_yemas_carga",
          },
          {
            name: "Pitones Planta",
            ind: 5,
            tipo: "hoja2",
            var: "poda_c_piton_planta",
          },
          {
            name: "Yemas Pitón",
            ind: 6,
            tipo: "hoja2",
            var: "poda_c_yemas_piton",
          },
          {
            name: "Yemas Planta",
            ind: 7,
            tipo: "interna",
            var: "poda_d1_yemas_planta",
          },
          {
            name: "Yemas Há",
            ind: 8,
            tipo: "interna1",
            var: "poda_d2_yemas_ha",
          },
          {
            name: "(3) Fertilidad",
            ind: 9,
            tipo: "etapa",
            var: "nn",
            children: [
              {
                name: "Fertilidad Yemas (%)",
                ind: 10,
                tipo: "hoja2",
                var: "fert_c_fertilizacion_yemas",
              },
              {
                name: "Racimos Planta",
                ind: 11,
                tipo: "interna",
                var: "fert_d1_racimos_planta",
              },
              {
                name: "Racimos Há",
                ind: 12,
                tipo: "interna1",
                var: "fert_d2_racimos_ha",
              },
              {
                name: "(4) Desbrota",
                ind: 13,
                tipo: "etapa",
                var: "nn",
                children: [
                  {
                    name: "Racimos Cargador",
                    ind: 14,
                    tipo: "hoja2",
                    var: "desb_c_racimos_carga",
                  },
                  {
                    name: "Racimos Pitón",
                    ind: 15,
                    tipo: "hoja2",
                    var: "desb_c_racimos_piton",
                  },
                  {
                    name: "Racimos Planta",
                    ind: 16,
                    tipo: "interna",
                    var: "desb_d1_racimos_planta",
                  },
                  {
                    name: "Racimos Há",
                    ind: 17,
                    tipo: "interna1",
                    var: "desb_d2_racimos_ha",
                  },
                  {
                    name: "(5) Arreglo",
                    ind: 18,
                    tipo: "etapa",
                    var: "nn",
                    children: [
                      {
                        name: "Racimos Planta",
                        ind: 19,
                        tipo: "hoja2",
                        var: "arr_c_racimos_planta",
                      },
                      {
                        name: "Bayas Racimo",
                        ind: 20,
                        tipo: "hoja2",
                        var: "arr_c_bayas_racimo",
                      },
                      {
                        name: "Bayas Há",
                        ind: 21,
                        tipo: "interna1",
                        var: "arr_d2_bayas_ha",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "(06) Calibre",
        ind: 21,
        tipo: "etapa",
        var: "nn",
        children: [
          {
            name: "Calibre Envero (mm)",
            ind: 22,
            tipo: "hoja2",
            var: "cal_c_calibre_envero",
          },
          {
            name: "Porc. Crecimiento (%)",
            ind: 22,
            tipo: "hoja2",
            var: "cal_c_porcentaje_crecimiento",
          },
          {
            name: "Calibre Promedio (mm)",
            ind: 22,
            tipo: "interna",
            var: "cal_calibre_promedio",
          },
          {
            name: "Peso Baya Promedio (gr)",
            ind: 23,
            tipo: "interna1",
            var: "cal_peso_baya",
          },
        ],
      },
    ],
  };

  return data_headers;
}

export function process_list_ep_actual(ep_list_etapa, ep_list_anterior) {
  let iter = [];
  let ep_list_dep = [];
  let ind = 0;

  // //console.log("XX,etapa, ", ep_list_etapa)
  // //console.log("YY,etapa ant, ", ep_list_anterior)

  ep_list_etapa.forEach(function (item_etapa, index1) {
    ep_list_anterior.forEach(function (item_anterior, index2) {
      if (
        item_etapa.r_cuartel_id == item_anterior.r_cuartel_id &&
        item_etapa.id_ep != null &&
        item_etapa.id_ep != ""
      ) {
        let aux_x = Object.assign({}, item_etapa);
        // //console.log("XXX, aux_x ", aux_x)
        ep_list_dep[ind] = Object.assign(aux_x, getMetricasDerivadas(aux_x));
        ind = ind + 1;
      } else if (item_etapa.r_cuartel_id == item_anterior.r_cuartel_id) {
        let aux_y = Object.assign({}, item_anterior);
        ep_list_dep[ind] = Object.assign(aux_y, getMetricasDerivadas(aux_y));
        ind = ind + 1;
      }
    });
  });
  // //console.log("XX DEP,", ep_list_dep)

  return ep_list_dep;
}

export function group_list_ep_actual(ep_list) {
  let resp = d3.group(ep_list, (d) => d.r_variedad_id);

  return resp;
}

export function make_header(ep_list_dep) {
  let aux_main = {
    column_headers: [],
    row_headers: { name: "", ind: 0, tipo: "inicio", children: [] },
    rows: [],
  };

  let anterior_r_variedad_id = 0;
  let index_variedad = 0;
  let index_cuartel = 0;
  let index_ind = 1;

  ep_list_dep.forEach(function (item, index) {
    if (item.r_variedad_id != anterior_r_variedad_id) {
      index_cuartel = 0;
      if (index_variedad == 0) {
        aux_main.row_headers.children[index_variedad] = {
          name: item.r_variedad_nombre,
          ind: index_ind,
          tipo: "variedad",
          children: [],
        };
        index_variedad = index_variedad + 1;
        index_ind = index_ind + 1;

        aux_main.row_headers.children[index_variedad - 1].children[
          index_cuartel
        ] = { name: item.r_cuartel_nombre, ind: index_ind, tipo: "cuartel" };
        index_cuartel = index_cuartel + 1;
        index_ind = index_ind + 1;
      } else {
        aux_main.row_headers.children[index_variedad] = {
          name: "nn",
          ind: index_ind,
          tipo: "separador",
        };
        index_variedad = index_variedad + 1;
        index_ind = index_ind + 1;

        aux_main.row_headers.children[index_variedad] = {
          name: item.r_variedad_nombre,
          ind: index_ind,
          tipo: "variedad",
          children: [],
        };
        index_variedad = index_variedad + 1;
        index_ind = index_ind + 1;

        aux_main.row_headers.children[index_variedad - 1].children[
          index_cuartel
        ] = { name: item.r_cuartel_nombre, ind: index_ind, tipo: "cuartel" };
        index_cuartel = index_cuartel + 1;
        index_ind = index_ind + 1;
      }
    } else {
      aux_main.row_headers.children[index_variedad - 1].children[
        index_cuartel
      ] = { name: item.r_cuartel_nombre, ind: index_ind, tipo: "cuartel" };
      index_cuartel = index_cuartel + 1;
      index_ind = index_ind + 1;
    }
    anterior_r_variedad_id = item.r_variedad_id;
  });

  aux_main.row_headers.children[index_variedad] = {
    name: "nn",
    ind: index_ind,
    tipo: "separador",
  };

  // //console.log("aux_main, ", aux_main)

  return aux_main;
}

export function make_header_labor(labor_list) {
  var nested = nest()
    .key(function (d) {
      return d.r_variedad_id;
    })
    .key(function (d) {
      return d.r_cuartel_id;
    })
    .rollup(nest_aggr_labor)
    .entries(labor_list);

  let aux_row_headers = { name: "", ind: 0, tipo: "inicio", children: [] };

  let aux_rows = [];

  let iter = 0;

  nested.forEach(function (item, index) {
    aux_row_headers.children[index] = {
      name: item.values[0].value.r_variedad_nombre,
      ind: iter,
      tipo: "variedad",
      children: [],
    };

    aux_rows.push(item.values[0].value);
    iter = iter + 1;

    item.values.forEach(function (item2, index2) {
      //  //console.log("auxxxx, ", item2.value )
      aux_row_headers.children[index].children[index2] = {
        name: item2.value.r_cuartel_nombre,
        ind: iter,
        tipo: "cuartel",
      };

      aux_rows.push(item2.value);
      iter = iter + 1;
    });
  });

  //console.log("aux headers, ", aux_row_headers)

  return { row_headers: aux_row_headers, rows: aux_rows };
}

export function make_header_prod(ep_list_dep) {
  let group = d3.groups(ep_list_dep, (d) => d.r_variedad_id);

  let aux_row_headers = { name: "", ind: 0, tipo: "inicio", children: [] };

  // let aux_rows = []

  let iter = 1;
  let iter_0 = 0;

  group.forEach(function (item, index) {
    if (iter_0 > 0) {
      aux_row_headers.children[iter_0] = {
        name: "",
        ind: iter,
        tipo: "separador",
      };
      iter = iter + 1;
      iter_0 = iter_0 + 1;
    }

    aux_row_headers.children[iter_0] = {
      name: item[1][0].r_variedad_nombre,
      ind: iter,
      tipo: "variedad",
      children: [],
    };
    iter = iter + 1;
    iter_0 = iter_0 + 1;

    item[1].forEach(function (item2, index2) {
      aux_row_headers.children[iter_0 - 1].children[index2] = {
        name: item2.r_cuartel_nombre,
        ind: iter,
        tipo: "cuartel",
      };
      iter = iter + 1;
    });
  });

  //console.log("aux headers, ", aux_row_headers)

  return aux_row_headers;
}

export function make_indented_table_prod(list_ep_actual) {
  let group = d3.groups(list_ep_actual, (d) => d.r_variedad_id);

  // let group2 = d3.group(list_ep_actual, d => (d.r_variedad_id.toString()+"-"+d.r_etapa_actual.toString()))

  //  //console.log("GROUP 2 DT, ", group2)

  let rollup = d3.rollup(list_ep_actual, nest_aggr_ep, (d) => d.r_variedad_id);

  // let groupingFns = [d => d.r_variedad_id, d => d.r_etapa_actual]

  // let rollup2 = d3.rollup(list_ep_actual,nest_aggr_ep,...groupingFns)

  // //console.log("ROULLP2 DT, ", rollup2)

  let aux_rows = { name: "", ind: 0, tipo: "inicio", children: [] };

  // let aux_rows = []

  let iter = 1;
  let iter_0 = 0;

  group.forEach(function (item, index) {
    if (iter_0 > 0) {
      aux_rows.children[iter_0] = { name: "", ind: iter, tipo: "separador" };
      iter = iter + 1;
      iter_0 = iter_0 + 1;
    }

    aux_rows.children[iter_0] = {
      name: item[1][0].r_variedad_nombre,
      ind: iter,
      tipo: "variedad",
      children: [],
    };

    aux_rows.children[iter_0].ep = rollup.get(item[1][0].r_variedad_id);

    iter = iter + 1;
    iter_0 = iter_0 + 1;

    item[1].forEach(function (item2, index2) {
      aux_rows.children[iter_0 - 1].children[index2] = {
        name: item2.r_cuartel_nombre,
        ind: iter,
        tipo: "cuartel",
      };
      aux_rows.children[iter_0 - 1].children[index2].ep = item2;
      iter = iter + 1;
    });
  });

  //  //console.log("aux headers XXY, ", aux_rows)

  return aux_rows;
}

export function get_list_ep_actual(ep_list) {
  let ind = 0;
  let aux_list = [];
  let list_con_ep_etapa = [];

  ep_list.forEach(function (item, index) {
    if (item.r_etapa_actual == item.k_etapa || item.r_etapa_actual === 0) {
      let aux_x = Object.assign({}, item);
      aux_x.r_ep_anterior_real_etapa = 2;
      aux_x = Object.assign(aux_x, getMetricasDerivadas(aux_x));
      aux_list[ind] = aux_x;
      ind = ind + 1;
      list_con_ep_etapa[ind] = item.r_cuartel_id;
    }
  });

  ep_list.forEach(function (item, index) {
    if (
      item.r_etapa_actual - 1 == item.k_etapa &&
      !list_con_ep_etapa.includes(item.r_cuartel_id)
    ) {
      let aux_y = Object.assign({}, item);
      aux_y = Object.assign(aux_y, getMetricasDerivadas(aux_y));
      aux_y.r_ep_anterior_real_etapa = 0;
      aux_list[ind] = aux_y;
      ind = ind + 1;
    }
  });

  return aux_list;
}

export function get_list_ep_con_metricas_der(ep_list) {
  let ind = 0;
  let aux_list = [];

  ep_list.forEach(function (item, index) {
    let aux_x = Object.assign({}, item);
    aux_x = Object.assign(aux_x, getMetricasDerivadas(aux_x));
    aux_list[ind] = aux_x;
    ind = ind + 1;
  });

  return aux_list;
}

export function nest_aggr_ep(d) {
  return {
    r_variedad_nombre: d3.max(d, function (e) {
      return e.r_variedad_nombre;
    }),
    k_etapa: d3.min(d, function (e) {
      return e.k_etapa;
    }),
    antec_hectareas: d3.sum(d, function (e) {
      return e.antec_hectareas;
    }),
    num_cuarteles: d3.sum(d, function (e) {
      return 1;
    }),
    antec_plantas_hectareas: d3.mean(d, function (e) {
      return e.antec_plantas_hectareas;
    }),

    teoricas_ha: d3.mean(d, function (e) {
      return 10000 / (e.antec_distancia_entre * e.antec_distancia_sobre);
    }),
    teoricas_cuartel: d3.mean(d, function (e) {
      return (
        e.antec_hectareas *
        (10000 / (e.antec_distancia_entre * e.antec_distancia_sobre))
      );
    }),
    teoricas_totales: d3.sum(d, function (e) {
      return (
        e.antec_hectareas *
        (10000 / (e.antec_distancia_entre * e.antec_distancia_sobre))
      );
    }),
    //teoricas_totales_teoricas_ha: teoricas_totales_teoricas_ha,

    prod_d2_cajas_totales: d3.sum(d, function (e) {
      return e.prod_d2_cajas_totales;
    }),
    prod_d1_cajas_hectareas:
      d3.sum(d, function (e) {
        return e.prod_d1_cajas_hectareas * e.antec_hectareas;
      }) /
      d3.sum(d, function (e) {
        return e.antec_hectareas;
      }),

    prod_kilos_hectareas:
      d3.sum(d, function (e) {
        return e.prod_kilos_hectareas * e.antec_hectareas;
      }) /
      d3.sum(d, function (e) {
        return e.antec_hectareas;
      }),
    prod_c_exportacion:
      d3.sum(d, function (e) {
        return e.prod_c_exportacion * e.antec_hectareas;
      }) /
      d3.sum(d, function (e) {
        return e.antec_hectareas;
      }),

    poda_c_carga_planta:
      d3.sum(d, function (e) {
        return e.poda_c_carga_planta * e.antec_hectareas;
      }) /
      d3.sum(d, function (e) {
        return e.antec_hectareas;
      }),
    poda_c_yemas_carga:
      d3.sum(d, function (e) {
        return e.poda_c_yemas_carga * e.antec_hectareas;
      }) /
      d3.sum(d, function (e) {
        return e.antec_hectareas;
      }),
    poda_c_piton_planta:
      d3.sum(d, function (e) {
        return e.poda_c_piton_planta * e.antec_hectareas;
      }) /
      d3.sum(d, function (e) {
        return e.antec_hectareas;
      }),
    poda_c_yemas_piton:
      d3.sum(d, function (e) {
        return e.poda_c_yemas_piton * e.antec_hectareas;
      }) /
      d3.sum(d, function (e) {
        return e.antec_hectareas;
      }),
    poda_d1_yemas_planta:
      d3.sum(d, function (e) {
        return e.poda_d1_yemas_planta * e.antec_hectareas;
      }) /
      d3.sum(d, function (e) {
        return e.antec_hectareas;
      }),
    poda_d2_yemas_ha:
      d3.sum(d, function (e) {
        return e.poda_d2_yemas_ha * e.antec_hectareas;
      }) /
      d3.sum(d, function (e) {
        return e.antec_hectareas;
      }),

    fert_c_fertilizacion_yemas:
      d3.sum(d, function (e) {
        return e.fert_c_fertilizacion_yemas * e.antec_hectareas;
      }) /
      d3.sum(d, function (e) {
        return e.antec_hectareas;
      }),
    fert_d1_racimos_planta:
      d3.sum(d, function (e) {
        return e.fert_d1_racimos_planta * e.antec_hectareas;
      }) /
      d3.sum(d, function (e) {
        return e.antec_hectareas;
      }),
    fert_d2_racimos_ha:
      d3.sum(d, function (e) {
        return e.fert_d2_racimos_ha * e.antec_hectareas;
      }) /
      d3.sum(d, function (e) {
        return e.antec_hectareas;
      }),

    desb_c_racimos_carga:
      d3.sum(d, function (e) {
        return e.desb_c_racimos_carga * e.antec_hectareas;
      }) /
      d3.sum(d, function (e) {
        return e.antec_hectareas;
      }),
    desb_c_racimos_piton:
      d3.sum(d, function (e) {
        return e.desb_c_racimos_piton * e.antec_hectareas;
      }) /
      d3.sum(d, function (e) {
        return e.antec_hectareas;
      }),
    desb_d1_racimos_planta:
      d3.sum(d, function (e) {
        return e.desb_d1_racimos_planta * e.antec_hectareas;
      }) /
      d3.sum(d, function (e) {
        return e.antec_hectareas;
      }),
    desb_d2_racimos_ha:
      d3.sum(d, function (e) {
        return e.desb_d2_racimos_ha * e.antec_hectareas;
      }) /
      d3.sum(d, function (e) {
        return e.antec_hectareas;
      }),

    arr_c_racimos_planta:
      d3.sum(d, function (e) {
        return e.arr_c_racimos_planta * e.antec_hectareas;
      }) /
      d3.sum(d, function (e) {
        return e.antec_hectareas;
      }),
    arr_c_bayas_racimo:
      d3.sum(d, function (e) {
        return e.arr_c_bayas_racimo * e.antec_hectareas;
      }) /
      d3.sum(d, function (e) {
        return e.antec_hectareas;
      }),
    arr_d2_bayas_ha:
      d3.sum(d, function (e) {
        return e.arr_d2_bayas_ha * e.antec_hectareas;
      }) /
      d3.sum(d, function (e) {
        return e.antec_hectareas;
      }),

    cal_c_calibre_envero:
      d3.sum(d, function (e) {
        return e.cal_c_calibre_envero * e.antec_hectareas;
      }) /
      d3.sum(d, function (e) {
        return e.antec_hectareas;
      }),
    cal_c_porcentaje_crecimiento:
      d3.sum(d, function (e) {
        return e.cal_c_porcentaje_crecimiento * e.antec_hectareas;
      }) /
      d3.sum(d, function (e) {
        return e.antec_hectareas;
      }),
    cal_calibre_promedio:
      d3.sum(d, function (e) {
        return e.cal_calibre_promedio * e.antec_hectareas;
      }) /
      d3.sum(d, function (e) {
        return e.antec_hectareas;
      }),
    cal_peso_baya:
      d3.sum(d, function (e) {
        return e.cal_peso_baya * e.antec_hectareas;
      }) /
      d3.sum(d, function (e) {
        return e.antec_hectareas;
      }),

    cal_peso_baya:
      d3.sum(d, function (e) {
        return e.cal_peso_baya * e.antec_hectareas;
      }) /
      d3.sum(d, function (e) {
        return e.antec_hectareas;
      }),
  };
}

export const podaYemasPlantas = (
  cargaPlantas,
  yemasCarga,
  pitonPlanta,
  yemasPiton
) => {
  return cargaPlantas * yemasCarga + pitonPlanta * yemasPiton;
};

export function get_list_labor_actual(labor_list) {
  let iter = [];
  let list_con_labor = [];
  let labor_list_dep = [];
  let ind = 0;

  let group = d3.groups(labor_list, (d) => d.r_cuartel_id);

  labor_list.forEach(function (item, index) {
    //console.log("labor item ", item)

    if (item.r_etapa_actual == item.k_etapa) {
      let aux_x = Object.assign({}, item);
      labor_list_dep.push(aux_x);
      list_con_labor.push(item.r_cuartel_id);
    }
  });

  //console.log("list con labor, ", list_con_labor)

  group.forEach(function (item, index) {
    if (!list_con_labor.includes(item[0])) {
      let aux_z = {
        r_variedad_nombre: item[1][0].r_variedad_nombre,
        r_variedad_id: item[1][0].r_variedad_id,
        r_cuartel_nombre: item[1][0].r_cuartel_nombre,
        r_cuartel_id: item[1][0].r_cuartel_id,
        r_etapa_actual: item[1][0].r_etapa_actual,
        k_etapa: null,
        fecha_inicio_prog: "",
        fecha_fin_prog: "",
        poda_c_carga_planta: null,
        poda_c_yemas_carga: null,
        poda_c_piton_planta: null,
        poda_c_yemas_piton: null,

        desb_c_racimos_carga: null,
        desb_c_racimos_piton: null,

        arr_c_racimos_planta: null,
        arr_c_bayas_racimo: null,
      };
      // //console.log("AUX_Z,", aux_z)
      labor_list_dep.push(aux_z);
    }
  });

  //  //console.log("labor list dep ", labor_list_dep)

  return labor_list_dep;
}

export function nest_aggr_labor(d) {
  return {
    r_variedad_nombre: d3.max(d, function (e) {
      return e.r_variedad_nombre;
    }),
    r_variedad_id: d3.max(d, function (e) {
      return e.r_variedad_id;
    }),
    r_cuartel_nombre: d3.max(d, function (e) {
      return e.r_cuartel_nombre;
    }),
    r_cuartel_id: d3.max(d, function (e) {
      return e.r_cuartel_id;
    }),
    fecha_inicio_prog: d3.max(d, function (e) {
      return e.fecha_inicio_prog;
    }),
    fecha_fin_prog: d3.max(d, function (e) {
      return e.fecha_fin_prog;
    }),
    r_etapa_actual: d3.max(d, function (e) {
      return e.r_etapa_actual;
    }),
    k_etapa: d3.min(d, function (e) {
      return e.k_etapa;
    }),

    poda_c_carga_planta: d3.mean(d, function (e) {
      return e.poda_c_carga_planta;
    }),
    poda_c_yemas_carga: d3.mean(d, function (e) {
      return e.poda_c_yemas_carga;
    }),
    poda_c_piton_planta: d3.mean(d, function (e) {
      return e.poda_c_piton_planta;
    }),
    poda_c_yemas_piton: d3.mean(d, function (e) {
      return e.poda_c_yemas_piton;
    }),

    desb_c_racimos_carga: d3.mean(d, function (e) {
      return e.desb_c_racimos_carga;
    }),
    desb_c_racimos_piton: d3.mean(d, function (e) {
      return e.desb_c_racimos_piton;
    }),

    arr_c_racimos_planta: d3.mean(d, function (e) {
      return e.arr_c_racimos_planta;
    }),
    arr_c_bayas_racimo: d3.mean(d, function (e) {
      return e.arr_c_bayas_racimo;
    }),
  };
}

export function get_ep_real(
  ep_anterior,
  res_recoleccion,
  etapa_visible,
  cuartel_ha
) {
  if (etapa_visible == 1) {
    let aux_a = {
      antec_plantas_hectareas: ep_anterior.antec_plantas_hectareas,
      antec_hectareas: cuartel_ha,
      antec_calidad_planta: roundToDec(res_recoleccion.n_poda_calidad_planta),

      poda_c_carga_planta: roundToDec(res_recoleccion.n_poda_cargadores_planta),
      poda_c_yemas_carga: roundToDec(
        res_recoleccion.n_poda_yemas_cargadores /
          res_recoleccion.n_poda_cargadores_planta
      ),
      poda_c_piton_planta: roundToDec(res_recoleccion.n_poda_pitones_planta),
      poda_c_yemas_piton: isNaN(
        roundToDec(
          res_recoleccion.n_poda_yemas_pitones /
            res_recoleccion.n_poda_pitones_planta
        )
      )
        ? 0
        : roundToDec(
            res_recoleccion.n_poda_yemas_pitones /
              res_recoleccion.n_poda_pitones_planta
          ),

      fert_c_fertilizacion_yemas: ep_anterior.fert_c_fertilizacion_yemas,

      desb_c_racimos_carga: ep_anterior.desb_c_racimos_carga,
      desb_c_racimos_piton: ep_anterior.desb_c_racimos_piton,
      arr_c_racimos_planta: roundToDec(ep_anterior.arr_c_racimos_planta),
      arr_c_bayas_racimo: ep_anterior.arr_c_bayas_racimo,
      cal_c_calibre_envero: ep_anterior.cal_c_calibre_envero,
      cal_c_porcentaje_crecimiento: ep_anterior.cal_c_porcentaje_crecimiento,
      cal_peso_baya: ep_anterior.cal_peso_baya,
      prod_c_exportacion: ep_anterior.prod_c_exportacion,
      modificacion: res_recoleccion.fecha_ultima,
      creacion: res_recoleccion.fecha_primera,
      //  cal_peso_baya: ep_anterior.cal_peso_baya,
    };

    let aux2_a = getMetricasDerivadas(aux_a);

    return Object.assign(aux_a, aux2_a);

    // setDataPrecargada("data_ep_real")
  } else if (etapa_visible == 2) {
    let aux_b = {
      antec_plantas_hectareas: ep_anterior.antec_plantas_hectareas,
      antec_hectareas: cuartel_ha,
      antec_calidad_planta: roundToDec(ep_anterior.antec_calidad_planta),

      poda_c_carga_planta: ep_anterior.poda_c_carga_planta,
      poda_c_yemas_carga: ep_anterior.poda_c_yemas_carga,
      poda_c_piton_planta: ep_anterior.poda_c_piton_planta,
      poda_c_yemas_piton: ep_anterior.poda_c_yemas_piton,

      fert_c_fertilizacion_yemas: roundToDec(
        (100 *
          (res_recoleccion.n_fert_racimos_cargadores +
            res_recoleccion.n_fert_racimos_piton)) /
          (ep_anterior.poda_c_carga_planta * ep_anterior.poda_c_yemas_carga +
            ep_anterior.poda_c_piton_planta * ep_anterior.poda_c_yemas_piton)
      ),

      desb_c_racimos_carga: ep_anterior.desb_c_racimos_carga,
      desb_c_racimos_piton: ep_anterior.desb_c_racimos_piton,

      arr_c_racimos_planta: ep_anterior.arr_c_racimos_planta,
      arr_c_bayas_racimo: ep_anterior.arr_c_bayas_racimo,
      cal_c_calibre_envero: ep_anterior.cal_c_calibre_envero,
      cal_c_porcentaje_crecimiento: ep_anterior.cal_c_porcentaje_crecimiento,
      cal_peso_baya: ep_anterior.cal_peso_baya,
      prod_c_exportacion: ep_anterior.prod_c_exportacion,
      modificacion: res_recoleccion.fecha_ultima,
      creacion: res_recoleccion.fecha_primera,
      //   cal_peso_baya: ep_anterior.cal_peso_baya,
    };

    let aux2_b = getMetricasDerivadas(aux_b);

    // //console.log("aux_b ", aux_b)

    return Object.assign(aux_b, aux2_b);
    // setDataPrecargada("data_ep_real")
  } else if (etapa_visible == 3) {
    let aux_b = {
      antec_plantas_hectareas: ep_anterior.antec_plantas_hectareas,
      antec_hectareas: cuartel_ha,
      antec_calidad_planta: roundToDec(ep_anterior.antec_calidad_planta),

      poda_c_carga_planta: ep_anterior.poda_c_carga_planta,
      poda_c_yemas_carga: ep_anterior.poda_c_yemas_carga,
      poda_c_piton_planta: ep_anterior.poda_c_piton_planta,
      poda_c_yemas_piton: ep_anterior.poda_c_yemas_piton,
      fert_c_fertilizacion_yemas: ep_anterior.fert_c_fertilizacion_yemas,

      desb_c_racimos_carga:
      ep_anterior.poda_c_carga_planta === 0 ?
      0 : 
      roundToDec( res_recoleccion.n_desb_racimos_cargadores / ep_anterior.poda_c_carga_planta ),

      desb_c_racimos_piton:
      ep_anterior.poda_c_piton_planta === 0 ?
      0 : 
      roundToDec( res_recoleccion.n_desb_racimos_piton / ep_anterior.poda_c_piton_planta ),

      arr_c_racimos_planta: ep_anterior.arr_c_racimos_planta,
      arr_c_bayas_racimo: ep_anterior.arr_c_bayas_racimo,
      cal_c_calibre_envero: ep_anterior.cal_c_calibre_envero,
      cal_c_porcentaje_crecimiento: ep_anterior.cal_c_porcentaje_crecimiento,
      cal_peso_baya: ep_anterior.cal_peso_baya,
      prod_c_exportacion: ep_anterior.prod_c_exportacion,
      modificacion: res_recoleccion.fecha_ultima,
      creacion: res_recoleccion.fecha_primera,
      //   cal_peso_baya: ep_anterior.cal_peso_baya,
    };

    let aux2_b = getMetricasDerivadas(aux_b);

    // //console.log("aux_b ", aux_b)

    return Object.assign(aux_b, aux2_b);
    // setDataPrecargada("data_ep_real")
  } else if (etapa_visible == 4) {
    let aux_c = {
      antec_plantas_hectareas: ep_anterior.antec_plantas_hectareas,
      antec_hectareas: cuartel_ha,
      antec_calidad_planta: roundToDec(ep_anterior.antec_calidad_planta),

      poda_c_carga_planta: ep_anterior.poda_c_carga_planta,
      poda_c_yemas_carga: ep_anterior.poda_c_yemas_carga,
      poda_c_piton_planta: ep_anterior.poda_c_piton_planta,
      poda_c_yemas_piton: ep_anterior.poda_c_yemas_piton,
      fert_c_fertilizacion_yemas: ep_anterior.fert_c_fertilizacion_yemas,
      desb_c_racimos_carga: ep_anterior.desb_c_racimos_carga,
      desb_c_racimos_piton: ep_anterior.desb_c_racimos_piton,

      arr_c_racimos_planta: roundToDec(res_recoleccion.n_arr_racimos_planta),
      arr_c_bayas_racimo: roundToDec(res_recoleccion.n_arr_bayas_racimo),

      cal_c_calibre_envero: ep_anterior.cal_c_calibre_envero,
      cal_c_porcentaje_crecimiento: ep_anterior.cal_c_porcentaje_crecimiento,
      cal_peso_baya: ep_anterior.cal_peso_baya,
      prod_c_exportacion: ep_anterior.prod_c_exportacion,
      modificacion: res_recoleccion.fecha_ultima,
      creacion: res_recoleccion.fecha_primera,
    };

    //console.log("res recoleccion, ", res_recoleccion)
    let aux2_c = getMetricasDerivadas(aux_c);

    // //console.log("aux_c ", aux_c)

    //  //console.log("aux2_c ", aux2_c)

    let aux_c_fin = Object.assign(aux_c, aux2_c);

    return aux_c_fin;
    // setDataPrecargada("data_ep_real")
  } else if (etapa_visible == 5) {
    let aux_c = {
      antec_plantas_hectareas: ep_anterior.antec_plantas_hectareas,
      antec_hectareas: cuartel_ha,
      antec_calidad_planta: ep_anterior.antec_calidad_planta,
      poda_c_carga_planta: ep_anterior.poda_c_carga_planta,
      poda_c_yemas_carga: ep_anterior.poda_c_yemas_carga,
      poda_c_piton_planta: ep_anterior.poda_c_piton_planta,
      poda_c_yemas_piton: ep_anterior.poda_c_yemas_piton,
      fert_c_fertilizacion_yemas: ep_anterior.fert_c_fertilizacion_yemas,
      desb_c_racimos_carga: ep_anterior.desb_c_racimos_carga,
      desb_c_racimos_piton: ep_anterior.desb_c_racimos_piton,

      arr_c_racimos_planta: ep_anterior.arr_c_racimos_planta,
      arr_c_bayas_racimo: ep_anterior.arr_c_bayas_racimo,

      cal_c_calibre_envero: roundToDec(res_recoleccion.n_cal_calibre_envero),

      cal_c_porcentaje_crecimiento: ep_anterior.cal_c_porcentaje_crecimiento,
      cal_peso_baya: ep_anterior.cal_peso_baya,
      prod_c_exportacion: ep_anterior.prod_c_exportacion,
      modificacion: res_recoleccion.fecha_ultima,
      creacion: res_recoleccion.fecha_primera,
    };

    //console.log("res recoleccion, ", res_recoleccion)
    let aux2_c = getMetricasDerivadas(aux_c);

    // //console.log("aux_c ", aux_c)

    //  //console.log("aux2_c ", aux2_c)

    let aux_c_fin = Object.assign(aux_c, aux2_c);

    return aux_c_fin;
    // setDataPrecargada("data_ep_real")
  } else {
    let aux3 = Object.assign({}, getEmptyEP());
    return aux3;
  }
}

export const getHistogram = (data, metrica) => {
  // let histogram = d3.histogram().thresholds([metrica.rangos]);
  // let bins = histogram(data);
  // //console.log(bins)
  let bin = d3.bin();
  let result = bin.thresholds(metrica.rangos);
  //console.log("result", result)
  return result;
  // return {
  //   number : [],
  //   percent: []
  // }
};

export const roundToDec = (val) => {
  return Math.round(val * 10) / 10;
};

export const getPesoBaya = (params_peso, calibre) => {
  //console.log("multiplicador_x, ",params_peso.variedad_multiplicador_x )
  //console.log("exponente, ",params_peso.variedad_exponente )
  //console.log("calibre, ",calibre )

  //console.log("power, ",Math.round( params_peso.variedad_multiplicador_x* Math.pow(calibre, params_peso.variedad_exponente)*10/10) )
  return (
    Math.round(
      params_peso.variedad_multiplicador_x *
        Math.pow(calibre, params_peso.variedad_exponente) *
        10
    ) / 10
  );
};

export const metricaEp2Final = (metrica) => {
  // console.log("metrica en metEp2Fibnal, ", metrica)
  let aux = {
    cal_c_porcentaje_crecimiento: "final_porc_crecimiento",
    cal_calibre_promedio: "final_calibre_promedio",
    cal_peso_baya: "final_peso_baya_promedio",
    cal_d1_peso_racimo: "final_peso_racimo",
    prod_kilos_hectareas: "final_kilos_ha",
    prod_c_exportacion: "final_porc_exportacion",
    prod_d2_cajas_totales: "final_cajas_totales",
    prod_d1_kilos_mercado_interno: "final_kilos_mercado_interno",
  };
  // console.log("aux[metrica]",aux[metrica])

  return aux[metrica];
};

export const validatorNumbers = (number) => {
  if (number === "NaN" || isNaN(number)) {
    return "S/I";
  } else {
    return number;
  }
};

// REGEX //
export const rutRegex = /\b[0-9|.]{1,10}-[K|k|0-9]/; // con o sin puntos, pero con guión.
export const latitudeRegex = /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/;
export const longitudeRegex = /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/;

// Glosa

export const getGlosaRol = (rol_id) => {
  switch (rol_id) {
    case 0:
      return "Super Admin";
    case 1:
      return "Empresa Admin";
    case 2:
      return "Empresa Editor";
    case 3:
      return "Empresa sólo Ver";
    case 4:
      return "Predio";
    default:
      "";
  }
};

export const getGlosaRolPredio = (rol_id) => {
  switch (rol_id) {
    case 0:
      return "";
    case 1:
      return "Editar y Cerrar Etapas";
    case 2:
      return "Sólo Editar";
    case 3:
      return "Sólo Ver";
    case 9:
      return "Sin Acceso";

    default:
      "";
  }
};

export const calculaRentabilidad = (ep, costoIngreso, etapa) => {
  // console.log("Parametro costoIngreso CalculaRentabilidad",costoIngreso);
  // 'rentabilidad_esc_2_ha': round(ingreso_ha + costo_ingreso['precio_exp_esc_2_caja']*cajas_ha - costo_ha, 2),
  if (
    costoIngreso === null ||
    costoIngreso === undefined ||
    ep === null ||
    ep.id === ""
  ) {
    return "";
  }
  let kilos_ha = Math.round(ep.prod_kilos_hectareas);
  let cajas_ha = Math.round(kilos_ha * (ep.prod_c_exportacion / 100 / 8.4), 2);
  let kilos_mercado_interno_ha = kilos_ha * (1 - ep.prod_c_exportacion / 100);
  let costo_ha = 0;
  if (costoIngreso.is_cosecha === 1 && costoIngreso.is_embalaje === 1) {
    costo_ha =
      costoIngreso.costo_hasta_cosecha_ha +
      costoIngreso.costo_otros_ha +
      costoIngreso.costo_cosecha_y_limpieza_caja * cajas_ha +
      costoIngreso.costo_embalaje_y_puesto_en_frio_caja * cajas_ha;
    // console.log("costo_ha", costo_ha);
  } else if (costoIngreso.is_cosecha === 1 && costoIngreso.is_embalaje === 0) {
    costo_ha =
      costoIngreso.costo_hasta_cosecha_ha +
      costoIngreso.costo_otros_ha +
      costoIngreso.costo_cosecha_y_limpieza_caja * cajas_ha;
  } else if (costoIngreso.is_cosecha === 0 && costoIngreso.is_embalaje === 1) {
    costo_ha =
      costoIngreso.costo_hasta_cosecha_ha +
      costoIngreso.costo_otros_ha +
      costoIngreso.costo_embalaje_y_puesto_en_frio_caja * cajas_ha;
  } else {
    costo_ha =
      costoIngreso.costo_hasta_cosecha_ha + costoIngreso.costo_otros_ha;
  }

  // CALCULO INGRESO POR HA
  let ingreso_ha =
    costoIngreso.precio_mercado_interno_kg * kilos_mercado_interno_ha +
    costoIngreso.ingreso_otro_caja * cajas_ha +
    costoIngreso.ingreso_otro_ha;
  let rentabilidad_esc_2_ha = Math.round(
    ingreso_ha + costoIngreso.precio_exp_esc_2_caja * cajas_ha - costo_ha,
    2
  );
  // console.log("Ingreso_ha", ingreso_ha);
  // console.log("precio_exp_esc_2_caja", costoIngreso.precio_exp_esc_2_caja);
  // console.log("cajas_ha", cajas_ha);
  // console.log("costo_ha", costo_ha);
  // console.log("Rentabilidad_esc_2",rentabilidad_esc_2_ha);

  return rentabilidad_esc_2_ha;

};


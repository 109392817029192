import React from "react";
import { useReactiveVar } from "@apollo/client";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  dashboardLayoutRoutes2,
  presentationLayoutRoutes,
  superAdminDashboardLayoutRoutes,
} from "./index2";

import DashboardLayout2 from "../layouts2/Dashboard2";
import PresentationLayout from "../layouts/Presentation";
import AuthLayout from "../layouts/Auth";
import Page404 from "../pages2/auth/Page404";

import useInitCurrentSelection from "../globalfunctions/useInitCurrentSelection";
import { AutorizacionRoutes } from "../globalfunctions/Autorizacion";

import {
  usuario_config,
  selectedLanguage,
} from "../graphql/localstate/localState";

const childRoutes = (Layout, routes) =>
  routes.map(({ component: Component, guard, children, path }, index) => {
    const Guard = guard || React.Fragment;

    return children ? (
      children.map((element, index) => {
        const Guard = element.guard || React.Fragment;

        return (
          <Route
            key={index}
            path={element.path}
            exact
            render={(props) => (
              <Guard>
                <Layout>
                  <element.component {...props} />
                </Layout>
              </Guard>
            )}
          />
        );
      })
    ) : Component ? (
      <Route
        key={index}
        path={path}
        exact
        render={(props) => (
          <Guard>
            <Layout>
              <Component {...props} />
            </Layout>
          </Guard>
        )}
      />
    ) : null;
  });

const Routes2 = () => {
  const [rutas_definitivas, set_rutas_definitivas] = React.useState(
    superAdminDashboardLayoutRoutes
  );
  const init_current_selection = useInitCurrentSelection();
  const usr_config = useReactiveVar(usuario_config);

  React.useEffect(() => {
    if (usr_config !== undefined) {
      set_rutas_definitivas(
        AutorizacionRoutes(usr_config, superAdminDashboardLayoutRoutes)
      );
    }
  }, [usr_config]);

  return (
    <Router>
      <Switch>
        {childRoutes(DashboardLayout2, rutas_definitivas)}
        {childRoutes(DashboardLayout2, presentationLayoutRoutes)}
        <Route
          render={() => (
            <AuthLayout>
              <Page404 />
            </AuthLayout>
          )}
        />
      </Switch>
    </Router>
  );
};

export default Routes2;

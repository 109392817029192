

export function AutorizacionPredio(usr_config, operacion, predio_id) {

  let aux_auto = false

  // SuperAdmin
  if (usr_config.usuario.rol_id === 0 && (operacion == 'ver' || operacion == 'editar' || operacion == 'cerrar')) aux_auto = true

  // Empresa Admin
  if (usr_config.usuario.rol_id === 1 && (operacion == 'ver' || operacion == 'editar' || operacion == 'cerrar')) aux_auto = true

  //Empresa Editor      
  if (usr_config.usuario.rol_id === 2 && (operacion == 'ver' || operacion == 'editar')) aux_auto = true

  // Empresa sólo Ver        
  if (usr_config.usuario.rol_id === 3 && operacion == "ver") aux_auto = true

  // Predio
  if (usr_config.usuario.rol_id === 4) {
    // Editar y cerrar etapas
    if (usr_config.permisos_predio_map.get(predio_id).rol_predio === 1 && (operacion == 'ver' || operacion == 'editar' || operacion == 'cerrar')) aux_auto = true
    // Solo editar
    if (usr_config.permisos_predio_map.get(predio_id).rol_predio === 2 && (operacion == 'ver' || operacion == 'editar')) aux_auto = true
    // Solo Ver
    if (usr_config.permisos_predio_map.get(predio_id).rol_predio === 3 && (operacion == 'ver')) aux_auto = true

     // Sin acceso.
     if (usr_config.permisos_predio_map.get(predio_id).rol_predio === 9 && (operacion == 'ver')) aux_auto = false
  }

  return aux_auto;

}


//sidebar routes

export const AutorizacionMenu = (usr_config, menu) => {
  let aux_auto = false;

  // SuperAdmin
  if (usr_config.usuario.rol_id === 0 && (menu === "empresa" || menu === "productor" || menu === "predio" || menu === "administracion")) aux_auto = true;
  // Empresa Admin
  if (usr_config.usuario.rol_id === 1 && (menu === "empresa" || menu === "productor" || menu === "predio")) aux_auto = true;
  // Empresa Editor
  if (usr_config.usuario.rol_id === 2 && (menu === "empresa" || menu === "productor" || menu === "predio")) aux_auto = true;
  // Empresa Sólo ver
  if (usr_config.usuario.rol_id === 3 && (menu === "empresa" || menu === "productor" || menu === "predio")) aux_auto = true;
  // Predio
  if (usr_config.usuario.rol_id === 4 && (menu === "predio")) aux_auto = true;

  return (aux_auto)

}

export const AutorizacionRoutes = (usr_config, routes) => {


  // SuperAdmin
  if (usr_config.usuario.rol_id === 0) {
    return routes;
  }
  // Empresa Admin
  if (usr_config.usuario.rol_id === 1) {

   return  routes.filter(function(e){ return !e.path.includes("mantenedor") && !e.path.includes("alarmas") && !e.path.includes("adminusuarios") })

  }

  // Empresa Editor
  if (usr_config.usuario.rol_id === 2) {
    return routes.filter(function(e){ return !e.path.includes("mantenedor") && !e.path.includes("alarmas") && !e.path.includes("adminusuarios")})
  }

  // Empresa Sólo ver
  if (usr_config.usuario.rol_id === 3) {
   return  routes.filter(function(e){ return !e.path.includes("mantenedor") && !e.path.includes("alarmas") && !e.path.includes("adminusuarios")})
  }

  // Predio
  if (usr_config.usuario.rol_id === 4) {
    return routes.filter(function(e){ return !e.path.includes("empresa") && !e.path.includes("productor") && !e.path.includes("mantenedor") && !e.path.includes("alarmas") && !e.path.includes("adminusuarios")})
  }

}

